.filter-by-leagues {
  width: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  .filter-inputs {
    max-height: 10em;
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 180px);
  }

  &.reduced-height {
    height: calc(100vh - 524px);
  }

  .league-multi-select {
    margin-bottom: 10px;
  }

  .search-btns-container {
    display: flex;
    align-items: center;
    gap: 3px;
    // flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;
    .search-btn {
      background-color: #5256c8;
      border: none;
      color: white;
      padding: 10px 17px;
      border-radius: 8px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #8f92f0;
      }
      @media (max-width: 576px) {
        font-size: 10px;
      }
    }
    .active-btn {
      background: #8f92f0;
    }
  }
}

.filter-by-leagues-apply-btn {
  margin-top: 20px;
  /* margin-bottom: 50px; */ /* Uncomment if needed */
  
  @media (max-width: 1000px) {
    // position: absolute;
    // bottom: 60px;
    // right: 20px;
  }
}

.calendar-leagues-text {
  font-size: 12px;
  font-weight: 700;
  color: #2e2e2e;
  padding-bottom: 12px;
}

.find-matches-btn-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  .find-matches-btn {
    background-color: #5256c8;
    color: white;
    font-weight: 700;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    border: none;
  }
}

.error-message {
  font-size: 12px;
  color: red;
  font-weight: 400;
}

.selected-competition {
  .deseelct-btn {
    background-color: transparent;
    text-decoration: underline;
    border: none;
    color: #5256c8;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: #8f92f0;
    }
    @media (max-width: 576px) {
      font-size: 10px;
    }
  }
}
