.search-form-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 30px;
  padding-top: 20px;
  height: 100%;
  @media (max-width: 768px) {
  padding: 0;
  padding-top: 40px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
    background-color: white;
  }
  .tab-buttons {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  .search-form {
    padding-top: 30px;
    width: 100%;
    height: calc(100% - 50px);
  }
  .intro-video {
    // position: absolute;
    // bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    // height: 100%;
  }
}

.remove-form-margin {
  @media (max-width: 1000px) {
    margin-top: 10px !important;
  }
}
