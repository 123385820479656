.live-matches {
  width: 100%;
  position: relative;
  .search-matches-input {
    // margin: 20px;
    width: 95%;
    margin: 20px auto 10px auto;
  }
  .icon-container {
    position: absolute;
    right: -40px;
    top: 55px;
    clear: both;
    z-index: 999;
    overflow-x: visible !important;
    overflow-y: visible !important;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #379af0;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
    @media (max-width: 1000px) {
      display: none !important;
    }
    &.red-bg {
      background-color: #f03737;
    }

    &.fav-container {
      top: 100px !important;
    }

    &.slide-down {
      top: 55px !important;
    }

    &.yellow-bg {
      background-color: #ffda1a;
    }

    &.grey-bg {
      background-color: #d8d8d8;
    }

    &.filter-btn-container {
      top: 10px;
    }
  }

  .hide-live {
    display: none;
  }

  .hide-spinner {
    display: none;
  }

  .show-spinner {
    display: block;
    height: calc(100vh - 115px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .live-list-section {
    width: 50%;
  }

  .live-details-section {
    position: absolute;
    width: 50%;
    right: 0;
    height: calc(100vh - 130px);
    padding: 0px 0 10px 10px;
    top: 55px;

    &.large-width {
      width: 100%;
      padding: 0;
    }
  }
}
