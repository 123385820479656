.live-match-details {
  height: 100%;
  margin-top: 20px;
  .live-match-close {
    position: relative;
    top: 10px;
    left: 6px;
  }

  .live-match-details-title {
    font-weight: 700;
    color: #2e2e2e;
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    height: auto;
  }
}
