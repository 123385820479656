.header {
  height: 60px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 4px 0px rgba(173, 173, 173, 0.6);
  position: relative;
  padding: 0px 30px 0 15px;

  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    text-decoration: underline;
  }
  @media (max-width: 1000px) {
    padding: 0 !important;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    box-shadow: none;
    height: auto;
  }
  .user-container {
    @media (max-width: 1000px) {
      display: none;
    }
    .profile-dropdown {
      position: relative;
      .user-login {
        background-color: transparent;
        font-size: 16px;
        color: black;
        text-decoration: none;
        cursor: pointer;
        font-family: Poppins;
        font-weight: 500;
        border: none;
        text-align: right;
        width: auto;
        min-width: 75px;
        white-space: nowrap;
        display: flex;
        gap: 10px;
        align-items: center;

        .user-icon {
          width: 22px;
          height: 22px;
        }
      }
      .profile-dropdown-content {
        position: absolute;
        top: 35px;
        right: 0;
        width: 120px !important;
        padding: 12px 0px;
        border-radius: 7px;
        background-color: white;
        width: 100%;
        transition: all 0.5s ease;

        li {
          padding: 2px 10px;
          margin-bottom: 5px;
          list-style-type: none;
          text-decoration: none;

          &:hover {
            background-color: rgb(187, 187, 187);
            color: white;
          }

          &:last-child {
            margin-bottom: 0px;
          }

          a {
            text-decoration: none;
            color: black !important;
          }
        }
      }
    }
    .header-login {
      background-color: transparent;
      font-size: 16px;
      color: black;
      text-decoration: none;
      cursor: pointer;
      font-family: Poppins;
      font-weight: 500;
      border: none;
      text-align: right;
      width: auto;
      min-width: 75px;
    }
  }

  &.header-slided {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
  }

  .logoContainer {
    // flex: 35%;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      position: absolute;
      top: 11px;
      left: 50%;
      display: none;
      transform: translateX(-50%);
    }
    .logo {
      width: 120px;
      object-fit: contain;
    }
  }
  // .tabs-container {
  //   flex: 30%;
  //   @media (max-width: 900px) {
  //     flex: 60%;
  //   }
  // }
  .profile-dropdown-container {
    flex: 35%;
    display: flex;
    justify-content: flex-end;
    display: none;

    @media (max-width: 1000px) {
      display: block;
      position: absolute;
      top: 10px;
      left: 15px;
      background-color: #fff;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-hamburger {
    @media (min-width: 1000px) {
      display: none;
    }
  }
}
