.filter-by-location {
  width: auto;

  @media (max-width: 1000px) {
    height: calc(100vh -180px);
    background-color: white;
  }
  @media (max-width: 1000px) {
    .find-match-button{
      // position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .find-match-button{
      display: flex;
      flex-direction: row;
      align-items: center;
      // position: absolute;
      // right: 10px;
      // bottom: 10px;
    }
  

  .autoComplete-container {
    margin-bottom: 15px;
  }
  .slider-container {
    width: auto;
    padding: 10px 30px;
    margin: 25px 0px;
    background-color: #f5f5f5;

    @media (max-width: 1000px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    .slider-title {
      font-weight: 500;
      font-size: 11px;
      margin-bottom: 38px;
    }
  }
}

.calendar-location-text {
  font-size: 12px;
  font-weight: 700;
  color: #2e2e2e;
}
.all-country-fixtures{
  font-size: 12px;
  margin: 6px
}

.countries-sections{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
  justify-content: center;

  img{
    width: 30px;
    height: 30px;
    border-radius: 20px;
    object-fit: cover;
  }
}