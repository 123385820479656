.custom-overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.custom-modal {
	position: relative;
	padding: 20px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-width: 400px;
	margin: auto;
	pointer-events: auto;
}
.container {
	flex-direction: column;
	padding-left: 0px;
	padding-top: 1rem;
	cursor: default;
}

.feedback-image {
	width: 60%;
	height: auto;
	margin: 20px 0;
}

.contact-us-button {
	background-color: #7444c2;
	color: #ffffff;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s ease;
	width: 100%;
}

.contact-us-button:hover {
	background-color: #5a34a0;
}

.contact-us-button:active {
	background-color: #422673;
}

@media (max-width: 600px) {
	.custom-modal {
		padding: 15px;
	}

	.feedback-image {
		margin: 15px 0;
	}

	.contact-us-button {
		font-size: 14px;
		padding: 8px 15px;
	}
}
