.fixture-details {
	height: calc(100vh - 280px);
	width: 100%;
	@media (max-width: 1000px) {
		margin-top: 20px;
	}
	.fixture-details-header {
		padding: 12px 0 12px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		.custom-header-buttons {
			margin-top: 5px !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-self: center;
			width: 100%;
			margin: 0 -1%;
		}
	}
	.fixtures-body {
		height: 90%;
		// overflow-y: auto;
		// @media (max-width: 1000px) {
		//   height: auto;
		// }
	}
}

.kiwi,
.bookCom {
	width: 20px;
	height: 20px;
}
