.home {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 110px);
  border: none;
  outline: none;
  

  @media (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  height: calc(100vh - 40px);
  }

  .maps-wrapper {
    flex-grow: 100;

    @media (max-width: 1000px) {
      flex-grow: 0;
      width: 100%;
      height: calc(58vh - 60px);
    }
    @media (max-width: 1000px) {
      height: calc(58vh - 60px);
    }
  }

  .zeroOpacity{
    opacity: 0.1;
  }

  .mapContent-wrapper {
    display: flex;
    justify-content: center;
    // align-items: center;
    // height: 100%; // tesitng for mobile UI
    @media (max-width: 1000px) {
      width: 100% !important;
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      background: rgb(255, 255, 255);
    }
  }
}

.map-overlay {
  // background: black;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  -moz-filter: blur(2px);
  -webkit-filter: blur(2px);

  @media (max-width: 1000px) {
    filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
  }
}

.mobile-fixtures-list-wrapper {
  height: calc(52vh);
  width: 100%;
  @media (max-height: 1000px) {
    height: 48vh;
  }
}

.hide {
  visibility: hidden;
}

.mobile-close-content {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  @media (max-width: 1000px) {
    left: auto !important;
    right: 10px !important;
  }
}
