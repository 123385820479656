.contact-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;

	@media screen and (max-width: 1200px) {
		margin-top: 70px;
	}
	.contact-left-section {
		max-width: 960px;
		width: 100%;
		padding: 0 84px 0 36px;
		display: flex;
		align-items: center;
		gap: 76px;
		@media screen and (max-width: 776px) {
			padding: 0 30px;
			gap: 30px;
		}
		@media screen and (max-width: 1200px) {
			margin-bottom: 30px;
		}
		.description {
			font-size: 16px !important;
		}
		.social-links {
			width: 27px;

			p {
				writing-mode: vertical-rl;
				text-orientation: sideways-right;
				transform: rotate(180deg);
				font-size: 14px;
				font-weight: 400;
			}
			.line {
				width: 1px;
				height: 143px;
				margin: 16px auto;
				border-left: 1px solid black;
			}
			.social-icons {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 19px;
			}
		}
		.contact-left {
			max-width: 737px;
			width: 100%;
			.heading {
				font-size: 30px;
				font-weight: 700;
				line-height: 45px;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					bottom: -10px;
					left: 0;
					width: 65px;
					height: 5px;
					border-radius: 7px;
					background-color: #6c63ff;
				}
			}
			p {
				font-size: 20px;
				font-weight: 300;
				line-height: 34.5px;
				margin: 20px 0 20px;
			}

			.input-container {
				display: grid;
				grid-template-columns: 1fr;
				gap: 21px;
				row-gap: 32px;
				margin-bottom: 20px;
				@media (min-width: 640px) {
					grid-template-columns: repeat(2, 1fr);
				}
				input {
					border: 1px solid #e2e2e2;
					border-radius: 7px;
					height: 35px;
					padding: 19px 25px 16px;
					font-size: 18px;
					font-weight: 500;
					color: #7d8185;
				}
				input::placeholder {
					font-size: 16px;
					// font-weight: 500;
					color: #7d8185;
				}
			}
			textarea {
				width: 100%;
				height: 100px;
				border-radius: 7px;
				resize: none;
				outline: none;
				border: 1px solid #e2e2e2;
				padding: 23px 25px;
				font-size: 18px;
				color: black;
				font-weight: 500;
				line-height: 27px;

				::placeholder {
					color: black;
				}
			}
			.submit-btn {
				border: none;
				background-color: #666bf1;
				color: white;
				font-size: 16px;
				font-weight: 400;
				padding: 13px 40px;
				border-radius: 10px;
				margin-top: 10px;
				margin-bottom: 1rem;
				cursor: pointer;

				@media screen and (max-width: 665px) {
					font-size: 16px;
					font-weight: 400;
					padding: 16px 70px 16px;
				}
			}
			.submit-btn:disabled {
				opacity: 0.6;
				cursor: not-allowed;
			}
		}
	}
	.contact-right {
		max-width: 1180px;
		width: 100%;
		height: calc(100vh - 67px);

		position: relative;

		@media screen and (max-width: 1200px) {
			display: none;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}
}
.btn-captcha-container {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
	@media screen and (max-width: 640px) {
		flex-direction: column;
	}
}
.captcha-btn {
	min-width: 300px;
	@media screen and (max-width: 640px) {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
