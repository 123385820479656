.supporters-details {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 334px);
  overflow: auto;

  .stadium-capacity {
    padding: 20px 15px;
    text-decoration: none;
    display: flex;
 
    flex-direction: column;
    justify-content: center;
    color: #2e2e2e;

    .details-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 20px 10px;
      font-size: 12px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      margin-bottom: 15px;
      border: 1px solid transparent;

      .details-content{
        display: flex;
        align-items: center;
        gap: 10px;

      }
    }
    .details-container:hover {
      border-color: #5256c8;
    }

    .directions-btn {
      background: #5256c8;
      width: 120px;
      text-decoration: none;
      color: white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 35px;
      border-radius: 5px;
      font-weight: bold;
      margin: 8px 0;
      font-size-adjust: 14px;
    }

    .stadium-capacity-venue {
      font-weight: 500;
      font-size: 18px;
      display: flex;
      display: flex;
      justify-content: space-between;
      .venue {
        width: 65%;
       }

      .stadium-rate {
        margin-top: 10px;
      }
      .stadium-reviews {
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        margin-top: 8px;
        cursor: pointer;
      }
    }
    .stadium-capacity-total {
      display: flex;
      flex-direction: row;
      align-items: center;
      .stadium-capacity-space {
        font-size: 12px;
        font-weight: 300;
        margin-right: 10px;
        margin-top: 1px;
      }
    }
  }
}

.commend-block{
  padding: 20px 20px 5px 20px;
  font-size: 12px;
  border-bottom: 1px solid #e9e9e9;

  .about {
    padding-bottom: 10px;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
      color: black; 
      font-size: 12px;
    }
  }

  .user-info{
    display: flex;    
    margin-bottom: 20px;
    align-items: center;

    .user-name{
      font-size: 17px;
      font-weight: 500;
      margin-right: 20px;
    }
  }

  .stadium-rate{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .reviews-modal-ratings {           
      margin-right: 25px;
      margin-bottom: 6px;

      .value-name{        
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
}
.comments{
  height: 300px;
  overflow: scroll;
}

.margin {
  margin-top: 0px !important;
}
