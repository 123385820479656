.summary {
  color: #2e2e2e;
  font-size: 12px;
  margin-bottom: 20px;
  height: 91.5%;
  padding: 20px 0;
  overflow-y: auto;
  .summary-details-title {
    color: #2e2e2e;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .summary-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    .event-time {
      font-size: 12px;
      font-weight: 200;
      position: absolute;

      @media (max-width: 1000px) {
        left: 5px;
      }
    }
    .summary-row {
      width: 100%;
      padding: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.top-border {
        border-top: 1px solid #ebebeb;
      }
      .home-team,
      .away-team {
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;

        .card {
          width: 9px;
          height: 12px;

          &.red-card {
            background: red;
            margin-top: 0;
            margin-bottom: 0;
          }

          &.yellow-card {
            background: yellow;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .away-team {
        span {
          margin-left: 5px;
        }
      }
      .home-team {
        span {
          margin-right: 5px;
        }
      }
      .middle-score {
        width: 10%;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}

.summary-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-summary-message {
  font-weight: 700;
  text-align: center;
  margin: 0 1%;
  position: relative;
  top: 1px;
  color: #2e2e2e;
}
