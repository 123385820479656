.match-details {
  padding: 20px 0 40px;

  .match-detail-cont {
    padding: 10px;
    padding-bottom: 55px;
    @media (max-width: 768px) {
      padding-bottom: 35px;
    }
    .see-more-btn {
      border: none;
      color: #5256c8;
      background-color: transparent;
      font-size: 19px;
    }
    h2 {
      margin-bottom: 3px;
      color: #2e2e2e;
      font-weight: 500;
      text-align: center;
      font-size: 16px;
    }
    p {
      font-size: 12px;
      font-weight: 300;
      color: #5b5b5b;
    }
    span {
      cursor: pointer;
    }
  }
}

.mtch-details-live-spinner {
  height: 91.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-detail-error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 640px) {
  .mobileMatchScroll {
    height: calc(100vh - 334px) !important;
  }
}
