.apple-btn {
	display: flex !important;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border: 1px solid #d8d8d8;
	background-color: white !important;
	width: 48%;
	height: 40px;
	padding: 0px 10px;
	display: block;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.1s;
}
.apple-icon {
	width: 20px;
	height: 20px;
	object-fit: cover;
}
.apple-login-button {
	display: none;
}
