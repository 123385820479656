.matches-not-found {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .matches-not-found-titles {
    color: #2e2e2e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .matches-not-found-mainTitle {
      font-weight: 700;
      font-size: 21px;
    }
    .matches-not-found-subTitle {
      font-weight: 300;
      font-size: 18px;
    }
  }

  .matches-not-found-img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
}
