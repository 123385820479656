.line-ups-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0px;

  .home-col,
  .away-col {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    text-align: left;
    .line-ups-row-number {
      font-weight: 200;
      font-size: 12px;
      margin-right: 15px;
      flex: 20%;
      text-align: center;
    }

    .line-ups-row-player {
      flex: 80%;
    }
  }
}
