.sort-modal-custom-btn {
  padding: 8px 0;
  text-align: center;
  width: 49%;
  background-color: #5b5b5b;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  &.inverse {
    background-color: white !important;
    border: 1px solid #5b5b5b;
    color: #5b5b5b;
  }
}
