.fixture-list-item-container {
	width: 100%;
	display: flex;
	justify-content: center;
	scroll-snap-align: start;
	position: relative;

	&:hover .list-item-caret {
		display: block;
	}

	.list-item-caret {
		display: none;
		position: absolute;
		right: -18px;
		top: 46px;

		&.caret-visible {
			display: block !important;
		}

		&.caret-not-visible {
			display: none !important;
		}
	}

	.fixture-list-item {
		// cursor: default;
		color: #2e2e2e;
		// height: 100px;
		margin-bottom: 9px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
		border: 1px solid transparent;
		width: 95%;

		// @media (max-width: 1000px) {
		//   height: 120px;
		// }

		&.selected-fixture-list-item {
			border: 1px solid #5256c8;
		}

		.logo-block {
			background-color: rgba(0, 0, 0, 0.04);
			padding: 10px 10px 10px 36px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			font-weight: 700;
			gap: 10px;

			@media (max-width: 576px) {
				font-size: 11px;
				gap: 0px;
			}

			.heart-icon {
				border: none;
				background-color: transparent;
				position: absolute;
				left: 20px;
				svg {
					width: 20px;
					height: 20px;
					color: rgb(244, 77, 105);

					@media (max-width: 576px) {
						width: 15px;
						height: 15px;
					}
				}
			}

			.side-left-col {
				width: 100%;
				display: flex;
				// width: 80%;
				// flex-grow: 1;
				align-items: center;
				gap: 10px;
				justify-content: center;

				.fixture-team {
					width: 150px;
					// width: 50%;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					// text-overflow: ellipsis;
					white-space: break-spaces;
					// overflow: hidden;

					.team-name {
						// text-overflow: ellipsis;
						white-space: break-spaces;
						// overflow: hidden;
						// padding-left: 10px;
						text-align: center;
					}

					&__fixture-team-awayTeam {
						// width: 50% !important;
						width: 150px;
						justify-content: flex-start !important;
						align-items: center !important;
						display: flex !important;
						// text-overflow: ellipsis !important;
						white-space: break-spaces !important;
						// overflow: hidden !important;

						.team-name {
							// text-overflow: ellipsis;
							white-space: break-spaces;
							// overflow: hidden;
							padding-right: 10px;
						}
					}
				}

				.vs-txt {
					font-weight: 700;
					font-size: 22px;
					text-align: center;

					@media (max-width: 576px) {
						font-size: 15px;
						font-weight: 500;
					}
				}

				.vs-date-txt {
					font-size: 10px;
					text-align: center;
					width: 60px;

					@media (max-width: 576px) {
						font-size: 8px;
					}
				}

				.team-logo {
					width: 25px;
					height: 25px;
					object-fit: cover;
					margin: 0px 10px;

					@media (max-width: 576px) {
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		.col-2 {
			display: flex;
			flex-direction: row;
			padding: 10px;
			width: 100%;
			gap: 20px;
			align-items: center;
			justify-content: space-between;

			.name-container {
				font-size: 12px;
				max-width: 143px;
				width: 100%;
			}

			.listItem-info-btn {
				background-color: #5256c8;
				border-radius: 3px;
				box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
				width: 120px;
				padding: 5px 0px;
				height: 25px;
				display: flex;
				justify-content: center;
				align-items: center;

				.listItem-info-btn-txt {
					color: white;
					font-weight: 900;
					font-size: 11px;
					margin-top: 1px;
					cursor: pointer;
				}
			}
			.listItem-info-btn-hide {
				visibility: hidden;
				height: 18px;
			}
			.distance {
				font-size: 12px;
				display: flex;
				align-items: center;
				gap: 5px;

				.radius {
					font-size: 12px;
					font-weight: 500;
					margin: 0 2px;

					@media (max-width: 800px) {
						font-size: 11px;
					}
				}

				@media (max-width: 800px) {
					font-size: 10px;
				}
			}

			.middle-col {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				height: 70%;
				width: 100% !important;

				.team-name {
					font-size: 15px;
					font-weight: 700;
					color: #2e2e2e;
					width: 60%;
					// text-overflow: ellipsis;
					white-space: break-spaces;
					// overflow: hidden;

					@media (max-width: 576px) {
						font-size: 12px !important;
					}
				}

				.date-time-container {
					display: flex;
					gap: 20px;
					justify-content: space-between;
					justify-content: end;
					font-size: 12px;
					align-items: center;

					@media (max-width: 800px) {
						font-size: 10px;
						width: 130px;
					}
					@media (max-width: 800px) {
						width: 125px;
						// height: 18px;
					}
					.time-container,
					.date-container {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 120px;
					}
				}
			}
			.share-btn {
				cursor: pointer;
			}

			.side-right-col {
				display: flex;
				flex-direction: column;
				width: 80%;
				height: 100%;
				align-items: flex-end;
				justify-content: center;

				.league-logo {
					width: 23px;
					height: 23px;
					object-fit: contain;
					@media (max-width: 1000px) {
						width: 18px;
						height: 18px;
					}
				}

				.league-name {
					font-weight: 700;
					color: #2e2e2e;
					font-size: 12px;

					@media (max-width: 800px) {
						font-size: 10px;
					}
				}

				.venue {
					font-weight: 300;
					color: #2e2e2e;
					font-size: 12px;

					.venue-country {
						text-transform: capitalize;
					}

					@media (max-width: 800px) {
						font-size: 10px;
						font-weight: 500;
						// width: 130px;
					}
				}
				.league-block {
					display: flex;
					align-items: center;
					width: 100%;

					> img {
						width: 35px;
						height: 35px;
						object-fit: contain;
						margin-right: 10px;

						@media (max-width: 1000px) {
							width: 25px;
							height: 25px;
						}
					}

					> div {
						font-size: 12px;
						white-space: break-spaces;

						@media (max-width: 1000px) {
							font-size: 10px;
						}
					}
				}
			}
		}
		.stadium-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			// flex-wrap: wrap;
			padding: 8px 10px;

			.car-distance {
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 2px;
				font-size: 11px;

				@media (max-width: 1000px) {
					font-size: 9px;
				}
			}

			.directions-btn {
				background: #5256c8;
				text-decoration: none;
				color: white;
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 30px;
				border-radius: 5px;
				font-weight: bold;
				font-size-adjust: 14px;
				padding: 5px;
				border: none;

				@media (max-width: 1000px) {
					height: 20px;
					padding: 3px;
				}
			}

			.stadium {
				display: flex;
				align-items: center;
				gap: 10px;
				font-size: 11px;
				max-width: 75%;

				@media (max-width: 1000px) {
					font-size: 9px;
				}

				img {
					width: 30px;
					height: 30px;
					object-fit: contain;

					@media (max-width: 1000px) {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}
}
.date-icon {
	margin-right: 2px;

	&.time-icon {
		margin-bottom: 1px;
	}
}

.date {
	font-weight: 500;
	margin-top: 1px;
	color: #5b5b5b;
	// text-overflow: ellipsis;
	white-space: break-spaces;
	// overflow: hidden;
	// width: 120px;
}

.fixtures-list-item-hover {
	&:hover {
		border: 1px solid #5256c8;
	}
}
.clickable {
	cursor: pointer;
}
.clickable:hover {
	text-decoration: underline;
}
.team-detail {
	width: 100%;
}

.live-matches-league {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 7px 0;
	gap: 5px;

	span {
		font-size: 13px;
		color: rgb(99, 99, 230);
	}
	img {
		width: 25px;
		height: 25px;
	}
}
