.location-search-input-container {
  position: relative;
  .location-search-input {
    border: 1px solid #5b5b5b;
    width: 100%;
    // box-shadow: 0px 3px 6px 1px rgba(173, 173, 173, 0.6);
    font-weight: 700;
    color: #2e2e2e;
    border-radius: 3px;
    font-size: 13px;
    outline: none;
    text-overflow: ellipses;
    padding: 0 35px 0 35px;
    height: 50px;
  }
  .search-icon {
    position: absolute;
    left: 10px;
    top: 15px;
  }

  .input-close-icon {
    position: absolute;
    right: 5px;
    top: 15px;
  }
}

.autocomplete-dropdown-container {
  position: relative;
  .absolute-list {
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);

    position: absolute;
    z-index: 99;
    width: 100%;
    .suggestion-item,
    .suggestion-item--active {
      padding: 10px !important;
      font-size: 13px !important;
    }
  }
}
