.list-show-all-matches {
  background-color: #ffda1a;
  cursor: pointer;
  font-weight: 700;
  align-self: center;
  // margin: 20px auto 0 auto;
  box-shadow: 1px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  width: 92%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 700;

  .list-show-all-matches-text {
    margin: 0 1%;
    .live-dot-container {
      height: 50px;

      display: flex;
      justify-content: center;
      align-items: center;
      .live-dot {
        height: 8px;
        width: 8px;
        margin-left: 6px;
        background-color: rgb(240, 79, 79);
        border-radius: 50%;
        display: inline-block;
        -webkit-animation: 1s blink ease infinite;
        -moz-animation: 1s blink ease infinite;
        -ms-animation: 1s blink ease infinite;
        -o-animation: 1s blink ease infinite;
        animation: 1s blink ease infinite;
      }
    }
  }
}
