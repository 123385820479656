html {
  width: 100% !important;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Poppins, sans-serif;
  overscroll-behavior: contain;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: local("Poppins"),
    url(./assets/fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.gmnoprint {
  display: none;
}

.gm-control-active,
.gm-fullscreen-control {
  display: none;
}

@media screen and (max-width: 1000px) {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    display: none !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 80px !important;
}

::-webkit-scrollbar-button {
  background-color: transparent;
  display: none;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 0, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 100px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.app-container {
  overflow: auto;
  padding-bottom: 20px;
  height: 100vh;
}

@media screen and (max-width: 1000px) {
  .app-container {
    overflow: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    position: relative;
    top: 2px;
    font-size: 18px !important;
  }
}

.react-responsive-modal-overlay {
  justify-content: center !important;
}

.slick-slide {
  height: auto;
}

.abs {
  display: list-item;
}
