.standings-wrapper {
  padding: 20px 0;
  height: 100%;
  overflow-y: scroll !important;
}

.no-standings {
  font-weight: 700;
  text-align: center;
  color: #2e2e2e;
}

.live-standings-spinner {
  height: 91.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
