.reviews-modal {
  width: 500px;
  @media (max-width: 1000px) {
    width: 800px;
  }
  .reviews-modal-header {
    position: relative;
    width: 100%;
    padding: 10px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    z-index: 999;
    box-shadow: 1px 1px 4px 0px rgba(173, 173, 173, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .reviews-modal-closeIcon {
      position: absolute;
      top: 15px;
      left: 15px;
    }
    .reviews-modal-header-logo {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 7px;
      right: 15px;
    }
    .reviews-modal-header-title {
      color: #2e2e2e;
      font-weight: 500;
      font-size: 14px;
      margin-top: -2px;
      position: absolute;
      top: 15px;
      left: 40px;
    }
  }

  .reviews-modal-content {
    .reviews-modal-map-container {
      height: 250px;
      width: 100%;      

      .inner-map-container {
        height: 70%;
      }

      .reviews-modal-supporter-details {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        height: 30%;
        background-color: #f5f5f5;

        .reviews-modal-supporter-title {
          font-size: 11px;
        }

        .reviews-modal-supporter-contact {
          text-align: center;
          width: 100%;
          font-size: 11px;
          padding: 0 10px;

          display: flex;
          justify-content: center;
          @media (max-width: 1000px) {
            font-size: 9px;
          }
          .reviews-modal-contact-col,
          .reviews-modal-contact-col-2 {
            line-height: 1;
            margin-right: 10px;
            width: 50%;
            @media (min-width: 1000px) {
              // margin: 0 2px;
            }
          }

          .reviews-modal-contact-col {
            // width: 30%;
            @media (max-width: 1000px) {
              // width: 50%;
            }
          }

          .reviews-modal-contact-col-2 {
            justify-content: flex-end;
            text-align: left;
            // width: 70%;
            @media (max-width: 1000px) {
              // width: 50%;
            }
          }
          .reviews-modal-location-icon {
            position: relative;
            top: 1px;
            margin-right: 2px;
          }
        }
        .reviews-modal-ratings {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .reviews-modal-content-list {
      padding: 10px 20px;
      overflow-y: scroll;
      .reviews-modal-content-title {
        font-weight: 700;
        text-align: center;
        font-size: 15px;
      }
    }
  }
}

.commend-block{
  padding: 20px 20px 5px 20px;
  font-size: 12px;
  border-bottom: 1px solid #e9e9e9;

  .user-info{
    display: flex;    
    margin-bottom: 20px;
    align-items: center;

    .user-name{
      font-size: 17px;
      font-weight: 500;
      margin-right: 20px;
    }
  }

  .stadium-rate{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .reviews-modal-ratings {           
      margin-right: 25px;
      margin-bottom: 6px;

      .value-name{        
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
}
.comments{
  height: 300px;
  overflow: scroll;
}



