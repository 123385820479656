.live {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);

  @media (max-width: 800px) {
    justify-content: flex-start;
    flex-direction: column;
  }

  .maps-wrapper {
    flex-grow: 100;

    @media (max-width: 800px) {
      flex-grow: 0;
      width: 100%;
      height: calc(48vh - 60px);
    }
    @media (max-width: 1000px) {
      height: calc(52vh - 60px);
    }
  }

  .mapContent-wrapper {
    display: flex;
    justify-content: center;
    // align-items: center;
    height: 100%; // tesitng for mobile UI
    @media (max-width: 800px) {
      width: 100% !important;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      background: rgb(255, 255, 255);
    }
  }
}

.map-overlay {
  background: black;
  background: rgba(0, 0, 0, 0.5);

  filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  -moz-filter: blur(2px);
  -webkit-filter: blur(2px);

  @media (max-width: 800px) {
    filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
  }
}

.mobile-fixtures-list-wrapper {
  height: 52vh;
  width: 100%;
  @media (max-height: 1000px) {
    height: 48vh;
  }
}

.hide {
  display: none;
}

.live-content {
  width: 100%;
  padding-top: 30px;
  &.live-content-gap {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.mobile-close-content {
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 1000;
}
