.supporter-info-btn {
  background-color: #ffda1a;
  cursor: pointer;
  color: #2e2e2e;
  font-weight: 300;
  font-size: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 150px;
  bottom: 25px;
  width: 100px;
  height: 45px;
  padding: 5px 0;
  box-shadow: 1px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  @media (max-width: 1000px) {
    left: 5px;
    bottom: 8px;
    width: 90px;
    height: 40px;
    font-size: 10px;
  }

  .supporter-info-team-comparison {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;

    .supporter-info-btn-img {
      width: 20px;
      height: 20px;
      object-fit: cover;

      @media (max-width: 1000px) {
        width: 18px;
        height: 18px;
      }
    }
  }
}
