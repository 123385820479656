.share-modal {
  .share-heading {
    text-decoration: underline;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .copy-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .link-input {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #dcdbdb;
      max-width: 250px;
      width: 100%;
      height: 100%;

      input {
        background-color: #dcdbdb;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        font-size: 14px;
        color: rgb(82, 82, 223);
        padding: 10px;
        outline: none;
        max-width: 250px;
        width: 100%;
        height: 100%;
        &::placeholder {
          color: rgb(82, 82, 223);
        }
      }
    }
    .copy-btn {
      background-color: black;
      border: none;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 5px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 60px;
    }
  }
  .social-share {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    justify-content: center;
  }
  .modal-closeBtn {
    width: 28px;
    height: 28px;
    background: transparent;
    border: navajowhite;
    margin-left: auto;
    display: block;
    margin-top: -14px;
    cursor: pointer;
    outline: none;
  }
  svg {
    width: 28px;
    height: 28px;
  }
}
