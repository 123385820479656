.sort-modal {
  width: 400px;

  @media (max-width: 1000px) {
    width: 300px;
  }

  @media (max-width: 800px) {
    width: 280px;
  }
}

.sort-modal-title {
  font-weight: 500;
  color: #5b5b5b;
  font-size: 14px;
  margin-bottom: 10px;
}

// input[type="checkbox"] {
//   -ms-transform: scale(1.5);
//   -moz-transform: scale(1.5);
//   -webkit-transform: scale(1.5);
//   -o-transform: scale(1.5);
//   transform: scale(1.5);
//   padding: 10px;
// }

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-color: #eee;

  border-radius: 5px;
}

.checkbox-text {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
  margin-top: 1px;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #379af0;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  border: solid white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border-width: 0 1px 1px 0;
}

.sort-modal-row1 {
  margin: 0 -1.2rem;
  padding: 15px 20px;
  border-bottom: 1px solid #ebebeb;
}

.sort-modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 370px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 270px;
  }
}
