.lineup {
  padding: 20px 10px 20px 0;
  text-align: center;
  color: #2e2e2e;
  font-size: 12px;
  height: 100%;
  overflow-y: auto;

  .lineup-spinner {
    height: 91.5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1000px) {
    padding: 20px 5px;
  }

  .subst {
    width: 100%;
  }
}

.lineup-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.not-available-text {
  font-weight: 700;
  color: #2e2e2e;
  text-align: center;
  margin-top: 20px;
}
