.header-tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  height: 60px;
  font-family: Poppins;
  font-weight: 500;
  @media (max-width: 1000px) {
    display: none;
  }
  &.mobile-header-tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: fit-content;
    gap: 10px;
    top: 45px;
  }

  .dropdown-container {
    position: relative;
    // padding-left: 10px;
    // padding-right: 10px;
    @media (max-width: 1000px) {
      display: none;
    }

    .nav-btn {
      border: none;
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      font-family: Poppins;
      transition: all 0.5s ease;
      cursor: pointer;
      outline: none;
    }
    .dropdown-content {
      position: absolute;
      top: 35px;
      left: 0;
      padding: 12px 10px;
      border-radius: 7px;
      background-color: white;
      width: 100%;
      transition: all 0.5s ease;

      li {
        padding-bottom: 10px;
        list-style-type: none;
        text-decoration: none;

        &:last-child {
          padding-bottom: 0; // No padding for the last li
        }
      }
    }
  }

  .mob-dropdown-item {
    text-decoration: none;
    color: #2e2e2e;
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .header-tab-item {
    // width: 23.3%;
    justify-content: center;
    align-items: center;
    display: flex;
    white-space: nowrap;
    // height: 30px;
    position: relative;
    color: #2e2e2e;
    text-decoration: none;
    cursor: pointer;

    &:last-of-type {
      width: 30%;
    }

    @media (max-width: 1000px) {
      width: 100% !important;
    }

    .live-dot {
      margin: 0 5%;
      height: 6px;
      width: 6px;
      position: relative;
      top: 1px;
      background-color: rgb(240, 79, 79);
      border-radius: 50%;
      display: inline-block;
      -webkit-animation: 1s blink ease infinite;
      -moz-animation: 1s blink ease infinite;
      -ms-animation: 1s blink ease infinite;
      -o-animation: 1s blink ease infinite;
      animation: 1s blink ease infinite;
    }
  }

  .active {
    border-top: 3px solid transparent;
    border-bottom: 3px solid #5256c8;
    color: #5256c8;
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
