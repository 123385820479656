.odds {
  margin-bottom: 20px;
}

.odds-title {
  color: #2e2e2e;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.odds-headerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  flex: 100%;
  border-top: 1px solid #ebebeb;
}

.odds-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 45%;
}

.odds-homeTeam {
  display: flex;
  justify-content: flex-end;
}

.odds-awayTeam {
  display: flex;
  justify-content: flex-start;
}
.odds-vsContainer {
  display: flex;
  flex: 10%;
  align-items: center;
  justify-content: center;
}
.odds-vsTxt {
  font-weight: 500;
  font-size: 11px;
}
.odds-teamLogo {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

.odds-teamName {
  font-size: 11px;
}

.odds-tableHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  border-top: 1px solid #ebebeb;
}
.odds-tableHeaderCol1 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  border-right: 1px solid #ebebeb;
  height: 45px;
}
.odds-tableHeaderTitle {
  color: #5256c8;
  font-weight: 500;
  font-size: 14px;
}
.odds-tableHeaderCol2 {
  display: flex;
  flex: 1.6;
}
