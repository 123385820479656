.mobile-fixtures-list-container {
	background-color: white;
	height: 100%;
	width: 100%;
	position: relative;
}
.week-day {
	background-color: rgb(0, 0, 0);
	display: flex;
	overflow-x: scroll;
	justify-content: space-between;
}
.mobile-fixtures-list {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scroll-snap-type: mandatory;
	scroll-snap-points-y: repeat(60px);
	scroll-snap-type: y mandatory;
	overflow-x: hidden;
	padding-bottom: calc(52vh - 22px);
	@media (max-height: 1000px) {
		padding-bottom: calc(52vh - 20px);
	}
}
.no-data-text {
	text-align: center;
	padding: 0 15px 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-container {
	height: 30px;
	.mobile-caret {
		position: absolute;
		z-index: 999;
		right: -10px;
		top: 27px;
		color: #29a762 !important;
		&.caret-live {
			top: 82px;
		}
	}
}
.mobile-fixtures-list-container .btn-container {
	margin: 0px;
}

.hide-spinner {
	display: none;
}

.show-spinner {
	display: block;
	height: calc(100vh - 115px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.live-matches-dates-container {
	height: 34px;
}
.ads-view {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	margin: 0 auto;
	width: 100%;
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	.ads-img {
		width: 200px;
		height: 60px;
		object-fit: cover;
	}
}

.mb-fix-list {
	height: 20%;
}

.mobile-card-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: 14px;
	margin-top: 20px;
	padding: 30px;
	text-align: center;
}

.count {
	position: relative;
}

.count-arrow-match {
	position: absolute;
	transform: translateY(-50%);
	top: 74px !important;
	right: -9px;
	z-index: 1;
	transition: 0.7s;

	// @media (min-width: 768px) {
	// top: 19% !important;
	// }
}

.count-arrow {
	position: absolute;
	transform: translateY(-50%);
	top: 75px !important;
	right: -9px;
	z-index: 1;
	transition: 0.7s;

	@media (min-width: 768px) {
		top: 17.5% !important;
	}
}

.all-matches-sect {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 10px;
	padding: 6px 10px;
	border-radius: 8px;
	font-weight: 700;
	width: 90%;
	box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
}

.mobile-fixture-team-container {
	// border-left: 3px solid rgb(60, 174, 163);
	padding: 5px 10px;
	padding-top: 0px;
	// margin-bottom: 9px;

	.vs-txt {
		font-weight: 700;
		font-size: 22px;
		text-align: center;

		@media (max-width: 576px) {
			font-size: 15px;
			font-weight: 500;
		}
	}

	.vs-date-txt {
		font-size: 10px;
		text-align: center;
		width: 60px;

		@media (max-width: 576px) {
			font-size: 8px;
		}
	}

	// :hover{
	//   background-color: rgb(230, 230, 230);
	// }

	.mobile-fixture-league {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 8px;

		.mobile-league-time,
		.mobile-league-name,
		.mobile-league-location {
			display: flex;
			gap: 3px;
			align-items: center;
			font-size: 10px;
		}
		.mobile-league-time {
			font-weight: 600;
		}
		// .mobile-league-time, .mobile-league-name, .mobile-fixture-league-right{
		// 	flex: 1;
		// }
		.mobile-league-name {
			// color: rgb(102, 102, 238);
			gap: 8px;
			color: white;
			background-color: rgb(217, 158, 47);
			padding: 2px 7px;
			border-radius: 100px;
			img {
				width: 15px;
				height: 15px;
				border-radius: 100%;
			}
		}
		.mobile-league-location {
			color: rgb(233, 75, 75);
			white-space: nowrap;
		}
	}
	.mobile-fixture-team {
		flex-grow: 1;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: 500;
		position: relative;

		.mobile-team-name {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			width: 50%;
			font-size: 12px;
			font-weight: 400;
			img {
				width: 20px;
				height: 20px;
			}
			// span {
			//   width: 100px;
			//   text-align: center;
			// }
		}

		.vs {
			font-weight: 300;
			font-size: 12px;
			background-color: white;
			border-radius: 100%;
			width: 22px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.mobileinfo-icon {
			position: absolute;
			right: 2px;
			top: 50%;
			transform: translateY(-50%);
			width: 12px;
			height: 12px;
			object-fit: contain;
			cursor: pointer;
			transition: color 0.3s;
		}
	}
}
