.signin-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 70px;
  }
  .sigin-left-section {
    max-width: 736px;
    width: 100%;
    padding: 0 86px 0 77px;
    @media screen and (max-width: 776px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 1200px) {
      margin-bottom: 30px;
    }
    .sigin-left {
      max-width: 573px;
      width: 100%;
      margin: auto;
      .heading {
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 65px;
          height: 5px;
          border-radius: 7px;
          background-color: #6c63ff;
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 34.5px;
        margin: 20px 0 25px;
      }
      .auth-container {
        width: 80%;

        @media (max-width: 768px) {
          width: 100%;
        }
        .button-container {
          display: flex;
          gap: 14px;
          flex-wrap: wrap;
          margin-bottom: 44px;

          .apple-btn,
          .google-btn {
            display: flex !important;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border: 1px solid #d8d8d8;
            background-color: white;
            width: 48%;
            height: 40px;
            padding: 0px 10px;
            display: block;
            border-radius: 100px;
            cursor: pointer;
            transition: all 0.1s;

            @media (max-width: 768px) {
              width: 100%;
            }

            &:hover {
              border-color: transparent;
              background-color: #666bf1;
              color: white;
            }

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 0;
              margin: 0;
            }

            .facebook-icon {
              width: 11px;
              height: 20px;
              object-fit: cover;
            }
            .google-icon {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }
          }
        }
        .line {
          border-top: 1px solid #000000;
          position: relative;
          margin-bottom: 40px;

          span {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0px 20px;
            background-color: white;
            display: block;
            font-size: 25px;
            font-weight: 400;
            line-height: 37px;
          }
        }
        .input-container {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          align-items: center;
          justify-content: space-between;
          input {
            border: 1px solid #e2e2e2;
            border-radius: 7px;
            height: 40px;
            padding: 19px 25px 16px;
            font-size: 16px;
            font-weight: 500;
            color: black;
          }
          input::placeholder {
            font-size: 16px;
            font-weight: 500;
            color: black;
          }
          .firstName-input,
          .lastName-input,
          .pass-input,
          .confirm-pass-input {
            width: 47.5%;

            @media (max-width: 768px) {
              width: 100%;
            }
          }
          .emails-input {
            width: 100%;
          }
        }
      }
      .reset-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        gap: 25px;
        margin-top: 5px;
        // padding-left: 15px;

        p {
          font-size: 14px;
          margin: 0px;

          span {
            color: #666bf1;
          }
        }
      }
      .checbox-container{
        display: flex;
        align-items: center;
        gap: 10px;
  
        .checkbox-input{
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        .checkbox-text{
          padding: 0;
          margin: 0;
          font-size: 14px;
        }
      }
      .login-btn {
        border: none;
        background-color: #666bf1;
        color: white;
        font-size: 17px;
        font-weight: 500;
        padding: 13px 40px;
        border-radius: 20px;
        margin-top: 30px;
        cursor: pointer;

        &:hover {
          border-color: transparent;
          background-color: rgb(121, 125, 248);
          color: white;
        }
      }
    }
  }
  .sigin-right {
    max-width: 1180px;
    width: 100%;
    height: calc(100vh - 67px);

    position: relative;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .img-text {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 66px;
      color: white;
      z-index: 10;

      h3 {
        font-size: 50px;
        font-weight: 700;
        // line-height: 150px;
      }
      p {
        font-size: 20px;
        font-family: 400;
        // list-style: 45px;
      }
    }
  }
}
