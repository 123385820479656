.odds-tableRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #ebebeb;
  height: 45px;
}
.odds-tableCol1 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ebebeb;
  height: 45px;
}
.odds-tableCol2 {
  display: flex;
  flex: 1.6;
  align-items: center;
  justify-content: center;
  height: 45px;
}
.odds-tableTxt {
  font-size: 14px;
  font-weight: 500;
  color: #5b5b5b;
}

.odds-betButton {
  display: flex;
  width: 80px;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.odds-betButton-img {
  width: 80px;
  height: 30px;
}
