.slider-modal-container {
  width: 400px;
  background-color: transparent !important;

  @media screen and (max-width: 576px) {
    width: 300px;
  }
  .slider-modal-content {
    position: relative;

    .closeIcon {
      position: absolute;
      z-index: 29;
      top: 10px;
      right: 10px;
      color: black !important;
      font-size: 20px;
      background-color: white;
      padding: 8px;
      border-radius: 10px;
    }

    .slick-prev {
      left: 12px;
      z-index: 1;
      transform: translateY(-50%);
      top: 50%;
    }
    .slick-next {
      right: 12px;
      z-index: 1;
      transform: translateY(-50%);
      top: 50%;
    }

    .image-container {
      padding: 0;
      margin: 0 0 -7px 0;
      width: 100%;
      height: 220px;
      @media screen and (max-width: 768px) {
        height: 180px !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
