.review-stepper {
  width: 100%;
  .text-content {
    padding: 0px 20px;
    height: 396px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-size: 35px;
      color: #2e2e2e;
      font-weight: 700;
      margin-bottom: 13px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e2e;
    }
  }

  .step-progress {
    margin-bottom: 0px;
    @media (max-width: 1400px) {
      padding: 0 20px;
    }
  }

  .step-bar {
    height: 18px;
    background-color: #e2e2e2;
    border-radius: 16px;
    @media (max-width: 768px) {
      height: 10px;
    }
  }

  .step-fill {
    height: 18px;
    background-color: #666bf1;
    transition: all 0.5s ease-in-out;
    @media (max-width: 768px) {
      height: 10px;
    }
  }

  .step-fill-1 {
    width: 35% !important;
    border-radius: 16px;
    @media (max-width: 1300px) {
      width: 30% !important;
    }
    @media (max-width: 768px) {
      width: 20% !important;
    }
  }

  .step-fill-2 {
    width: 66% !important;
    border-radius: 16px;
  }

  // .step-fill-3 {
  //   width: 78% !important;
  //   @media (max-width: 1300px) {
  //     width: 85% !important;
  //   }
  // }
  .stepper-optional {
    color: #cccccc;
  }
  .step-numbers span.completed {
    color: #20b953;
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .step-numbers {
    display: flex;
    justify-content: space-between;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
    padding: 26px 0;
    @media (max-width: 768px) {
      padding: 12px 30px;
    }
  }
  .step-numbers span {
    font-size: 18px;
    font-weight: bold;
    color: #2e2e2e;
    white-space: break-spaces;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .step-numbers span.active {
    color: #565be9;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  .stepper-section {
    padding: 76px 16px;
    background-color: #f5f5f5;
    @media (max-width: 576px) {
      padding: 16px 12px;
    }
    .stepper-content {
      max-width: 1391px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .move-btn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    margin: 60px auto 0;
    @media (max-width: 768px) {
      flex-direction: row;
      margin-top: 24px;
      max-width: 650px;
      width: 100%;
    }

    .continue-btn {
      cursor: pointer;
      background-color: #6c63ff;
      padding: 12px 24px;
      height: 45px;
      max-width: 250px;
      width: 100%;
      flex-shrink: 0;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      justify-content: space-between;
      display: flex;
      align-items: center;
      border: 0;
      margin: auto;

      @media (max-width: 768px) {
        max-width: auto;
        width: 45%;
        padding: 12px 15px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .both-btn {
      cursor: pointer;
      background-color: #6c63ff;
      padding: 12px 24px;
      height: 45px;
      max-width: 250px;
      width: 100%;
      flex-shrink: 0;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      justify-content: space-between;
      display: flex;
      align-items: center;
      border: 0;
      @media (max-width: 768px) {
        max-width: auto;
        width: 45%;
        padding: 12px 15px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .first-stepper {
    display: flex;
    gap: 90px;
    width: 100%;
    @media (max-width: 778px) {
      flex-direction: column;
      gap: 24px;
    }
    .first-stepper-left-section {
      width: 100%;

      .stepper-search-input {
        padding: 20px 33px;
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        background-color: white;
        color: #7d8185;
        font-size: 18px;
        font-weight: bold;
        z-index: 0;
        box-shadow: 0px 8px 15px 0px #00000012;
        border-radius: 4px;
        @media (max-width: 768px) {
          padding: 12px;
        }
        label {
          font-size: 15px;
          color: #7d8185;
          font-weight: bold;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .wrapper {
        width: 100%;
        border: none;
        border-radius: 0;
        background-color: white;
        color: #2e2e2e;
        font-size: 18px;
        font-weight: bold;
        z-index: 0;
        box-shadow: none;
        .search-icon {
          margin: 0;
        }
      }
    }
    .first-stepper-left-section {
      width: 100%;
    }
  }
  .second-stepper {
    display: flex;
    flex-direction: column;
    grid-gap: 36px;
    align-items: center;
    .first-card-section {
      width: 50%;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      padding: 18px 33px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        width: 100%;
        padding: 12px;
      }
    }
    .second-card-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .home-away {
        display: flex;
        gap: 18px;
        p {
          max-width: 150px;
          width: 100%;
          height: 42px;
          background: #e2e2e2;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          max-width: 150px;
          height: 42px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6c63ff;
          border-radius: 50px;
          color: white;
        }
      }
    }
    .third-card-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .fourth-card-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .card-fourth-1 {
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        padding: 18px 33px;
        background-color: white;
        @media (max-width: 768px) {
          padding: 12px;
        }
      }
    }
  }
  .third-stepper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .third-stepper-left-section {
      width: 100%;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      padding: 18px 33px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      @media (max-width: 768px) {
        padding: 12px;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
    .third-stepper-right-section {
      width: 100%;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      padding: 18px 33px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      @media (max-width: 768px) {
        padding: 12px;
      }
      .input-email {
        h4 {
          font-size: 15px;
          color: #7d8185;
          font-weight: bold;
          line-height: 22px;
          margin-bottom: 11px;
          @media (max-width: 768px) {
            font-size: 11px;
          }
        }
        input {
          color: 18px;
          color: #7d8185;
          border: 0;
        }
        input {
          color: 18px;
          color: #7d8185;
          border: 0;
        }
      }
    }
  }
}

.card-1,
.card-2 {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 18px 33px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
}
.card-styling {
  max-width: 650px;
  margin: auto;
  width: 100%;
}
input:not([type="checkbox"])
{
  color: #7d8185;
  border: 0;
  height: 28px;
  outline: none;
  width: 100%;
  font-size: 15px;
}

h4 {
  font-size: 15px;
  color: #7d8185;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
}

img {
  width: 45px;
  height: 32px;
}

.fourth-stepper {
  width: 100%;
  text-align: center;

  h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 5px;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .go-back-btn {
    width: 100%;
    max-width: 200px;
    background-color: #6c63ff;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    display: block;
    margin: 27px auto 20px;
    border: none;
  }
  .review-pub-btn {
    background-color: #ffda1a;
    cursor: pointer;
    padding: 24px;
    max-width: 320px;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #2e2e2e;
    gap: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 0;

    &:hover {
      opacity: 0.8;
    }
  }
  .footbal-img {
    display: block;
    margin: 48px auto 81px;
    width: 100%;
    max-width: 583px;
    height: 100%;
  }
  .review-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 12px;

    img {
      display: block;
      margin: 0;
    }
  }
}

.dropdown {
  margin: 15px auto 0;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 44px;
  padding: 0px 10px;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background-color: white;
  color: #7d8185;
  font-size: 14px;
  font-weight: bold;
  z-index: 0;
  box-shadow: 0px 8px 15px 0px #00000012;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.stepper-section {
  .stepper-search-input {
    padding: 20px 33px;
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background-color: white;
    color: #7d8185;
    font-size: 18px;
    font-weight: bold;
    z-index: 0;
    box-shadow: 0px 8px 15px 0px #00000012;
    border-radius: 4px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      padding: 12px;
    }
    label {
      font-size: 15px;
      color: #7d8185;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 12px;
        line-height: normal;
      }
    }
    .stepper-search-country {
      display: flex;
      align-items: center;
      gap: 30px;
      justify-content: space-between;
      gap: 30px;
      img {
        width: 33px;
        height: 33px;
      }
    }
  }
  .wrapper {
    width: 100%;
    padding: 0px 5px;
    border: 1px solid #e2e2e2;
    border-radius: 6px !important;
    background-color: white;
    color: #7d8185;
    font-size: 14px;
    font-weight: bold !important;
    z-index: 9999;
    box-shadow: none;
    input {
      color: #7d8185 !important;
      font-weight: bold !important;
    }
    ul li svg {
      display: none !important;
    }
    .ellipsis {
      display: flex !important;
      gap: 20px;
    }
    .search-icon {
      margin: 0;
    }
  }
}

.location-search-input {
  border: none !important;
  color: #7d8185 !important;
}

.attraction-card-style {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 12px;
}

.card-fourth-1 {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 18px 33px;
  background-color: white;
  // display: flex;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 768px) {
    padding: 12px;
  }
  .card-four-top {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .card-fourth-section {
    display: flex;
    flex-direction: column;
    textarea {
      min-height: 80px;
      border: none;
      outline: none;
      resize: none;
      color: #7d8185;
      padding: 8px 12px;
      background-color: #fbfbfb;
    }
  }
}
.error-message {
  font-size: 12px;
  color: red;
  font-weight: 400;
  margin: 5px 0px;
}
.error-asterisk {
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin-left: 4px;
  display: inline-block;
}
.review-pubs-button {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 40px;
  max-width: 650px;
  width: 100%;
  margin: auto;
  background-color: #ffda1a;
  cursor: pointer;
  padding: 24px;
  max-width: 320px;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #2e2e2e;
  gap: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 0;
  &:hover {
    opacity: 0.8;
  }
  &:nth-child(2) {
    background-color: #ff3535;
    color: white;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    margin-top: 0px;
  }
}

.dropdown-autocomplete {
   svg {
  display: none !important;
}
.css-1dimb5e-singleValue,
.css-1jqq78o-placeholder {
  color: #7d8185;
  font-size: 14px;
  font-weight: 700;
}
}
// .options-container{
//   display: flex;
// }
