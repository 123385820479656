.header-btn-container {
	width: 110px;
	height: 40px;
	display: flex;
	margin: 0 1%;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	border-radius: 3px;
	&.skyscanner-btn {
		// flex-direction: column;
		position: relative;
		gap: 2px;
		font-size: 9px;
	}

	.web-btn {
		margin-right: 3px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.web-btn-txt {
		font-size: 12px;
		font-weight: 500;
		color: #2e2e2e;
		margin-top: 3px;
	}

	.skyscanner-btn-txt {
		position: absolute;
		bottom: 4px;
	}

	.secondary-txt {
		color: #379af0;
	}
}

.kiwi-btn,
.bookingCom-btn {
	width: 110px;
	height: 40px;
	display: flex;
	margin: 0 1%;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	border-radius: 3px;
	gap: 6px;
	border: none;
	outline: none;
	background: white;
	cursor: pointer;
}
