.controls-btn {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 67%;
  width: 40px;
  height: 90px;
  z-index: 100;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  cursor: pointer;

  @media (min-width: 426px) and (max-height: 1000px) {
    bottom: 62%;
  }

  @media (max-width: 1000px) {
    right: 5px !important;
    bottom: 60px !important;
    width: 30px !important;
    height: 70px !important;
  }
}
.spinner {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;


}
.controls-btn {
  background: transparent !important;
  box-shadow: none !important;
  height: auto !important;
  width: auto !important;
  bottom: 10px !important;
  right: 10px !important;
}

.map-btn {
  width: 45px;
  height: 45px;
  background: #5256c8;
  text-align: center;
  line-height: 45px;
}

.leagues-btn {
  border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #5256c8;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    width: 43px;
    height: 43px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.radius-btn-off {
  border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #5256c8;
    font-size: 12px;
    color: rgb(255, 255, 255);
    width: 43px;
    height: 43px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.radius-btn-on {
  border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    font-size: 12px;
    color: rgb(0, 0, 0);
    width: 43px;
    height: 43px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.radius-btn-off span {
  font-size: 8px;
  color: white;
  line-height: 11px;
  font-weight: 100;
  letter-spacing: 0.5px;
}

.radius-btn-on span {
  font-size: 8px;
  color: black;
  line-height: 11px;
  font-weight: 100;
  letter-spacing: 0.5px;
}


.leagues-btn > div {
  line-height: 8px;
}

.focus-btn {
  border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: white;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    width: 43px;
    height: 43px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.focus-btn > div {
  line-height: 8px;
}

.focus-btn  svg {
  width: 30px;
  height: 24px;
}

.leagues-btn span {
  font-size: 8px;
  color: white;
  line-height: 11px;
  font-weight: 100;
  letter-spacing: 0.5px;
}

.focus-btn span {
  font-size: 9px;
  color: #333;
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
}

.leagues-btn svg {
  color: white !important;
  font-weight: 100;
  height: 21px;
  width: 20px;
}
.map-controls-minus-icon {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 26%;
}

.map-controls-icon {
  flex: 37%;
}

.show-map-location23 {
  background-color: white;
  cursor: pointer;
  font-weight: 700;
  padding: 5px 8px;
  height: 35px;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  font-size: 10px;
  color: #2e2e2e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  gap: 6px;
  font-family: poppins, sans-serif;
  position: absolute;
  right: 245px;
  bottom: 25px;
  white-space: nowrap;
}