.lastFiveMatches-wrapper {
  margin-bottom: 20px;
}

.lastFiveMatches-title {
  color: #2e2e2e;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.lastFiveMatches {
  margin-top: 5px;
}

.lastFiveMatches-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid #ebebeb;
}

.lastFiveMatches-teamHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 50%;
}

.lastFiveMatches-teamHeaderLogo {
  width: 20px;
  height: 20px;
}
.lastFiveMatches-teamHeaderName {
  font-size: 11px;
  padding-left: 8px;
}
