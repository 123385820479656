.sub-tabs {
    display: flex;
    flex-direction: column;
    height: calc(100% - 150px);
  }
  
  .main-tabs {
    height: 100%;
    // margin-top: 30px;
  }
  
  .tabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 8.5%;
    font-weight: 500;
    color: #2e2e2e;
    font-size: 11px;
    margin-right: 10px;
  
    &.home-details-tabs {
      height: 40px !important;
    }
  
    @media (max-width: 1000px) {
      font-size: 10px !important;
      margin-left: 10px;
      height: 40px;
    }
  
    &.box-shadow {
      box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
    }
  
    &.negative-margin {
      padding: 0 !important;
      justify-content: flex-start !important;
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
    }
  
    .tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12.5px;
      @media (max-width: 800px) {
        width: 33.3%;
      }
      @media (max-width: 640px){
        font-size: 11px;
      }
      @media (max-width: 420px){
        font-size: 10px;
      }
      @media (max-width: 388){
        font-size: 9px;
      }
      @media (max-width: 375){
        font-size: 8px;
      }
      &.active-tab {
        position: relative;
        color: #5256c8;
  
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: #5256c8;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  
  .tab-component {
    height: 91.5%;
  }
  
  .home-tab-component {
    height: calc(100% - 40px);
  }
  
  .sub-tab-component {
    height: calc(100% - 40px);
    & > div {
      height: auto;
      @media (max-width: 1000px) {
        height: 40vh !important;
      }
    }
  
    // div {
    //   @media (max-width: 1000px) {
    //     overflow: visible !important;
    //   }
    //   div {
    //     overflow: scroll;
    //     @media (max-width: 1000px) {
    //       overflow: visible !important;
    //     }
    //   }
    // }
    // @media (max-width: 1000px) {
    //   height: auto;
    // }
  }
  
  .live-details-tabs {
    height: calc(100vh - 339px) !important;
  }
  