.form-card {
  cursor: pointer;
  color: white;
  height: 120px;
  margin-bottom: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  align-self: center;
  background-image: linear-gradient(
      to bottom,
      rgba(66, 66, 66, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../../assets/images/stadium.jpeg");
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-size: cover;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  .form-card-title {
    font-weight: 900;
    font-size: 15px;
  }
  .form-card-subTitle {
    font-weight: 500;
    font-size: 13px;
  }
}
