.mobile-fixtures-list-buttons {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.mobile-fixtures-list-buttons .mflb-col {
  flex-wrap: wrap;
  justify-content: center;
}
.mobile-fixtures-list-container .btn-container .mobile-fixtures-list-buttons .mflb-col {
  justify-content: center;
}
.mobile-fixtures-list-buttons .mflb-col span {
  width: 100%;
  text-align: center;
}

.mobile-fixtures-list-container .btn-container .mobile-fixtures-list-buttons {
  padding: 4px 0 !important;
  background: transparent;
}

.mobile-fixtures-list-container .btn-container {
  height: auto;
}
.mflb-col {
  display: flex;
  align-items: center;

  &.mflb-col-2 {
    flex: 50%;
    justify-content: center;
  }

  &.mflb-col-1 {
    justify-content: flex-start;
  }
  &.mflb-col-3 {
    justify-content: flex-end;
  }

  &.mflb-col-1,
  &.mflb-col-3 {
    flex: 25%;
  }
}

.mflb-text {
  color: #5256c8;
  font-weight: 700;
  font-size: 10px;
  position: relative;
  top: 1px;

  .live-dot {
    height: 6px;
    width: 6px;
    position: relative;
    left: 1px;
    top: -1px;
    background-color: rgb(240, 79, 79);
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
  }
}

.mflb-side-text {
  color: #8f8f8f;
}

.mflb-side-icon,
.mflb-middle-icon {
  margin: 0 2px 0 0;
}
