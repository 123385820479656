.mobile-search-details {
  position: absolute;
  top: 57px;
  left: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 9px;
  @media (min-width: 1000px) {
    display: none;
  }

  .search-details-bg {
    border-radius: 15px;
    background-color: #ffda1a;
    padding: 3px 7px;
    font-size: 10px;
    margin-right: 4px;
    &.search-details-bg-black {
      background-color: #2e2e2e !important;
      color: white !important;
    }
  }
}
