.subst-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}
.substitute {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 20px 0 30px 0;
  font-size: 12px;
  height: 36px;

  @media (max-width: 1000px) {
    height: 32px;
    font-size: 10px;
  }
  .substitute-score {
    color: #5256c8;
    font-size: 16px !important;
    flex: 25%;
    font-weight: 500;

    @media (max-width: 1000px) {
      font-size: 12px;
    }
  }

  .substitute-details {
    flex: 75%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      display: flex;

      justify-content: space-evenly;
      align-items: center;
      &:first-child {
        color: #646464;
      }
      &:last-child {
        font-weight: 700;
      }

      span {
        line-height: 1;
        text-align: left;
        flex: 80%;
      }

      .substitute-icon {
        flex: 20%;
      }
    }
  }
}
