.stadium-images {
  padding: 10px;
  width: 100%;
  // overflow-y: auto;
  // height: 100%;

  .slick-track {
    display: flex !important;
    gap: 5px
  }

  .slick-prev {
    left: 12px;
    z-index: 1;
    transform: translateY(-50%);
    top: 50%;
  }
  .slick-next {
    right: 12px;
    z-index: 1;
    transform: translateY(-50%);
    top: 50%;
  }
  .stadium-img {
    width: 70px;
    height: 80px !important;
    outline: none;

    img {
      width: 100%;
      height: 100%;
      max-height: 80px !important;
      border-radius: 9px;
      object-fit: cover;
    }
  }
  // padding-bottom: 300px;
  .stadium-images-txt {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #2e2e2e;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.react-photo-gallery--gallery {
  div {
    img {
      border-radius: 8px !important;
    }
  }
}



