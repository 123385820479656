.userLocation {
  width: 20px;
  height: 20px;
}

.userLocation-icon {
  cursor: pointer;
  position: absolute;
  transform: translate(-20%, -85%);
}

.stadiumIcon {
  width: 40px;
  height: 30px;
  cursor: pointer;
  position: relative;
  top: -17px;
  left: -19px;
  z-index: 998;
}

.supporterIcon {
  cursor: pointer;
  position: absolute;
  // transform: translate(-20%, -85%);
  width: 15px;
  height: 15px;
}
.iconOnHover {
  cursor: pointer;
  position: absolute;
  // transform: translate(-20%, -85%);
  transform: scale(1); 
  transition: all .3s;
  z-index: 1001 !important;
}
.selectedStadiumIcon {
  position: relative;
  top: -54px !important;
  left: -22px !important;
  width: 72px;
  height: 72px;
  z-index: 999;
  cursor: pointer;
}

.flag-images-container {
  position: absolute;
  top: -53px;
  left: 2px;
  width: 47px;
  height: 31px;
  z-index: 1000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .flag-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.stadium-name {
  position: absolute;
  top: 20px;
  width: 120px;
  left: -60px;
  font-weight: 900;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(103, 116, 241);
  font-size: 12px !important;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.map-top-con {
  position: relative;
}

.map-spinner {
  display: block;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.map-container {
  width: 100%;
  height: calc(100vh - 60px);
  @media (max-width: 1000px) {
    height: calc(58vh - 60px);
    // position: relative;
  }
  @media (max-width: 1000px) {
    height: calc(58vh - 60px);
  }
}

.map-spinner-container {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.pub-flag-img-container {
  position: absolute;
  top: -36px;
  left: -3px;
  width: 31px;
  height: 20px;

  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .pub-flag-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.pub-flag-distance-container {
  position: absolute;
  top: 4px;
  left: -7px;
  // width: 50px;
  height: 20px;
  font-weight: 700;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .pub-flag-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
.marker-container{
  width: fit-content;
}
.attraction-popup{
 padding: 10px 10px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  z-index: 1001 !important;
  position: relative;
  width: fit-content;
  white-space: nowrap;

}

.supporter-info-container {
  height: 100%;
  position: relative;
}


.google-attr{
  position: relative;
  top: 40vh;
  right: 7vw;
  z-index: 999;
  // border: 5px solid black;
  // background-color: white;
  // height: 50px;
  // width: 200px;
}
.cluster-marker{
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: white !important;
  justify-content: center;
}

.cluster{
  display: flex;
  align-items: center;
  gap: 1px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  justify-content: center;
  color: blue;
  font-size: 16px;
  width:fit-content;
  padding: 1px 5px;
  border-radius: 8px;
  font-weight: 600;

  img{
    width: 40px;
    height: 28px;
  }
}

.cluster-container{
  display: flex;
  align-items: center;
  gap: 1px;
  background-color: transparent !important;
  justify-content: center;
  color: blue;
  font-size: 16px;
  width:fit-content;
  padding: 1px 5px;
  border-radius: 8px;
  font-weight: 600;

  img{
    width: 40px;
    height: 28px;
  }

  p{
    position: absolute;
    top: 3px;
    left: 45px;
    padding: 3px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}

.flagIcon {
  position: relative;
  top: -44px !important;
  left: -4px !important;
  width: 72px;
  height: 72px;
  z-index: 999;
  cursor: pointer;
}
.flag-container {
  position: absolute;
  top: -43px;
  left: 26px;
  width: 47px;
  height: 31px;
  z-index: 1000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .flag-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}