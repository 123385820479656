.lastFiveMatches-row {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
}

.lastFiveMatches-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: space-between;
  position: relative;
}

.lastFiveMatches-teamComparison {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  border-right: 1px solid #ebebeb;
  padding: 0 11px;
}

.lastFiveMatches-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  position: relative;
  @media (max-width: 1000px) {
    width: 39%;
  }
}

.lastFiveMatches-teamName {
  font-size: 8px;
  // margin-left: 2.2px;
  font-weight: 500;
  // position: relative;
  // top: 2px;
  margin-top: 2px;
  white-space: wrap !important;
  word-break: break-all;
  text-align: center;

}

.lastFiveMatches-score {
  display: flex;
  width: 35%;
  border: 1px solid #444444;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  @media (max-width: 1000px) {
    width: 22%;
    height: 13px;
    border-radius: 10px;
  }
}
.lastFiveMatches-scoreTxt {
  font-weight: 700;
  font-size: 13px;
  color: #2e2e2e;
  white-space: pre;

  @media (max-width: 1000px) {
    font-size: 10px;
  }
}

.border {
  position: absolute;
  height: 25px;
  width: 2px;
}

.lastFiveMatches-teamLogo {
  width: 20px;
  height: 20px;
}

.lastFiveMatches-date {
  text-align: center;
  font-size: 7px;
  position: absolute;
  align-self: center;
  bottom: 0;
}
