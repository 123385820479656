.filter-modal {
  max-width: 400px;
  @media (max-width: 1000px) {
    max-width: 330px;
  }
  @media (max-width: 576px) {
    max-width: 280px;
  }

  .filter-modal-header {
    position: relative;
    width: 100%;
    display: flex;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ebebeb;
    justify-content: center;
    align-items: center;
    .filter-modal-closeIcon {
      position: absolute;
      top: 15;
      left: 15px;
    }

    .filter-modal-header-title {
      color: #2e2e2e;
      font-weight: 500;
      // font-size: 14px;
      margin-top: 2px;
    }
  }

  .filter-modal-content {
    padding: 20px 20px 20px;
    font-size: 12px !important;
    @media (max-width: 1000px) {
      padding: 8px 13px 0;
    }
    .search-input-container {
      margin: 0 -40px;
      padding: 15px 40px;
      background-color: #f5f5f5;
    }
    .filterModal-leagueList-container {
      margin-bottom: 20px;
    }
  }
}

.modal-custom-apply-button-container {
  padding-right: 40px;
  margin-bottom: 1.2rem;

  @media (max-width: 1000px) {
    padding-right: 14px;
  }
}

.filterModal-checkbox {
  // margin: 6px;
  width: 20px;
  outline: none !important;
}

.all-leagues-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  color: #7d8185 !important;

  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }


  .all-leagues-checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #7d8185;
    font-size: 12px;
  }
  .toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-size: 12px !important;
    }

    .toggle-btn {
      display: flex;
      align-items: center;
    }
  }
}

.radio-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }

  .radio-one {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #7d8185;
    font-size: 12px;
    padding: 7px;
    border: 1px solid #379af0;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
    border-radius: 10px;
    cursor: pointer;  /* Make the whole block clickable */
    transition: all 0.3s ease;

    @media (max-width: 576px) {
      font-size: 10px;
      padding: 4px;
      gap: 6px;
    }

    input {
      width: 15px;
      height: 15px;
    }
    span {
      margin-left: 10px;
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(173, 173, 173, 0.6);
    }

    /* Optional: add a background color change on hover */
    &:hover {
      background-color: #f0f8ff;
    }
  }

  /* Ensure label fills the container */
  label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer; /* Makes the label clickable */
  }
}


.country-filter {
  position: relative;
  width: 32%;
  margin: 0 2px 4px 2px;
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: white;
  border-radius: 7px;
  border: 1px solid #d1cece;
  height: 85px;
  cursor: pointer;
  padding: 5px;
  font-size: 13px;

  @media (max-width: 1000px) {
    height: 75px;
  }

  &.selected-country-filter {
    border-color: #379af0;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  }

  .checkmark-icon {
    background-color: #379af0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 5px;

    @media (max-width: 768px) {
      width: 15px;
      height: 15px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.country-filter-wrapper {
  display: flex;
  flex-direction: row;
}

.show-matches-in {
  font-size: 14px;
  margin: 0 0 4px 0;
}
