.footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  color: white;
  background-color: rgb(18, 18, 18);
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  text-align: center;
  gap: 3px;
  .left-sec {
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 10px;
      display: none;
    }
  }

  .footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: 768px) {
      margin: 0 auto;
    }
    a {
      font-size: 14px;
      color: #379af0;
      @media (max-width: 768px) {
        font-size: 10px;
        text-align: center;
      }
    }
  }

  .social-icons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 0 auto;
      display: none;
    }
    .icon {
      color: black;
      background-color: white;
      transition: color 0.3s ease;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .icon:hover {
      background-color: #379af0;
    }
    .icon.disabled {
      pointer-events: none;
      color: #ccc; // Light grey for disabled icons
    }
  }
}
