.header-container {
  .reviews-header {
    margin-top: 10px;
    text-align: center;
    padding: 0px 20px;
    position: relative;
    .close-btn {
      position: absolute;
      left: 30px;
      top: 5px;
      cursor: pointer;

      @media (max-width: 1000px) {
        top: -15px;
        left: 20px;
      }
    }
    h2 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
    }
    @media (max-width: 1000px) {
      margin-top: 70px;
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .review-stepper {
    width: 100%;
    .text-content {
      padding: 0px 20px;
      height: 396px;
      width: 100%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 35px;
        color: #2e2e2e;
        font-weight: 700;
        margin-bottom: 13px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: #2e2e2e;
      }
    }

    .step-progress {
      margin-bottom: 0px;
      margin-top: 30px;
      @media (max-width: 1400px) {
        padding: 0 20px;
      }
    }

    .step-bar {
      height: 18px;
      background-color: #e2e2e2;
      border-radius: 16px;
      @media (max-width: 768px) {
        height: 10px;
      }
    }

    .step-fill {
      height: 18px;
      background-color: #666bf1;
      transition: all 0.5s ease-in-out;
      @media (max-width: 768px) {
        height: 10px;
      }
    }

    .step-fill-1 {
      width: 35% !important;
      border-radius: 16px;
      @media (max-width: 1300px) {
        width: 30% !important;
      }
      @media (max-width: 768px) {
        width: 20% !important;
      }
    }

    .step-fill-2 {
      width: 66% !important;
      border-radius: 16px;
    }

    // .step-fill-3 {
    //   width: 78% !important;
    //   @media (max-width: 1300px) {
    //     width: 85% !important;
    //   }
    // }
    .stepper-optional {
      color: #cccccc;
    }
    .step-numbers span.completed {
      color: #20b953;
      font-size: 18px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .step-numbers {
      display: flex;
      justify-content: space-between;
      max-width: 570px;
      width: 100%;
      margin: 0 auto;
      padding: 26px 0;
      @media (max-width: 768px) {
        padding: 12px 30px;
      }
    }
    .step-numbers span {
      font-size: 18px;
      font-weight: bold;
      color: #2e2e2e;
      white-space: break-spaces;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .step-numbers span.active {
      color: #565be9;
      font-weight: bold;
      font-size: 18px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .stepper-section {
      padding: 76px 16px;
      background-color: #f5f5f5;
      @media (max-width: 576px) {
        padding: 16px 12px;
      }
      .stepper-content {
        max-width: 1391px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .move-btn {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: row;
        margin-top: 24px;
      }
      .previous-btn {
        background-color: #2e2e2e;
        cursor: pointer;
        padding: 12px 24px;
        height: 45px;
        max-width: 250px;
        width: 100%;
        flex-shrink: 0;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 600;
        color: white;
        justify-content: space-between;
        display: flex;
        align-items: center;
        border: 0;
        &:hover {
          opacity: 0.8;
        }
        @media (max-width: 768px) {
          max-width: auto;
          width: 46%;
          padding: 12px 15px;
        }
      }
    }
    .first-stepper {
      display: flex;
      gap: 90px;
      width: 100%;
      @media (max-width: 778px) {
        flex-direction: column;
        gap: 24px;
      }
      .first-stepper-left-section {
        width: 100%;

        .stepper-search-input {
          padding: 20px 33px;
          width: 100%;
          border: 1px solid #e2e2e2;
          border-radius: 4px;
          background-color: white;
          color: #2e2e2e;
          font-size: 18px;
          font-weight: bold;
          z-index: 0;
          box-shadow: 0px 8px 15px 0px #00000012;
          border-radius: 4px;
          @media (max-width: 768px) {
            padding: 12px;
          }
          label {
            font-size: 15px;
            color: #7d8185;
            font-weight: bold;
          }
        }
        .jiMOeR .wrapper {
          width: 100%;
          border: none;
          border-radius: 0%;
          background-color: white;
          color: #2e2e2e;
          font-size: 18px;
          font-weight: bold;
          z-index: 0;
          box-shadow: none;
          .search-icon {
            margin: 0;
          }
        }
      }
      .first-stepper-left-section {
        width: 100%;
      }
    }
    .second-stepper {
      display: flex;
      flex-direction: column;
      grid-gap: 36px;
      align-items: center;
      .first-card-section {
        width: 50%;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        padding: 18px 33px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        @media (max-width: 768px) {
          width: 100%;
          padding: 12px;
        }
      }
      .second-card-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .home-away {
          display: flex;
          gap: 18px;
          p {
            max-width: 150px;
            width: 100%;
            height: 42px;
            background: #e2e2e2;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          span {
            max-width: 150px;
            height: 42px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #6c63ff;
            border-radius: 50px;
            color: white;
          }
        }
      }
      .third-card-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
      .fourth-card-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .card-fourth-1 {
          width: 100%;
          border: 1px solid #e2e2e2;
          border-radius: 6px;
          padding: 18px 33px;
          background-color: white;
          @media (max-width: 768px) {
            padding: 12px;
          }
        }
      }
    }
    .third-stepper {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .third-stepper-left-section {
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        padding: 18px 33px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        @media (max-width: 768px) {
          padding: 12px;
        }
        .input-email {
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .third-stepper-right-section {
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        padding: 18px 33px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        @media (max-width: 768px) {
          padding: 12px;
        }
        .input-email {
          h4 {
            font-size: 15px;
            color: #7d8185;
            font-weight: bold;
            line-height: 22px;
            margin-bottom: 11px;
            @media (max-width: 768px) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
.card-1,
.card-2 {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 18px 33px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
}
input:not([type="checkbox"]) 
{
  color: #2e2e2e;
  border: 0;
  height: 28px;
  outline: none;
  width: 100%;
  font-size: 15px;
}

h4 {
  font-size: 15px;
  color: #7d8185;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
}

img {
  width: 45px;
  height: 32px;
}

.fourth-stepper {
  width: 100%;
  text-align: center;

  h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 5px;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  button {
    width: 100%;
    max-width: 269px;
    height: 50px;
    background-color: #6c63ff;
    color: white;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    border-radius: 5px;
    display: block;
    margin: 27px auto 0;
    border: none;
  }
  .footbal-img {
    display: block;
    margin: 48px auto 81px;
    width: 100%;
    max-width: 583px;
    height: 100%;
  }
  .review-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 12px;

    img {
      display: block;
      margin: 0;
    }
  }
}

.dropdown {
  margin: 0px auto 40px;
  cursor: pointer;
  display: block;
  width: 50%;
  height: 44px;
  padding: 0px 10px;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background-color: white;
  color: #2e2e2e;
  font-size: 18px;
  font-weight: bold;
  z-index: 0;
  box-shadow: 0px 8px 15px 0px #00000012;
  @media (max-width: 768px) {
    width: 100%;
  }
}
