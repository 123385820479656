.attraction-modal {
  width: 450px;

  @media screen and (max-width: 576px) {
    width: 300px;
  }
  .attraction-modal-content {
    position: relative;
    // text-align: center;
    padding: 20px 20px 20px;

    @media screen and (max-width: 576px) {
      padding: 15px;
    }

    p {
      font-size: 14px;
    }

    .closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: rgb(209, 59, 59);
      font-size: 20px;
    }
    // .title-container {
    //   width: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 20px;
    //   margin: 30px auto 10px;
    //   p {
    //     width: 90%;
    //   }

    //   .pub-icon {
    //     width: 50px;
    //     height: 50px;
    //   }
    // }
    // .desc-container {
    //   width: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 20px;
    //   margin: 0 auto 10px;

    //   p {
    //     width: 90%;
    //   }
    // }

    // .disc {
    //   font-size: 12px;
    // }
    // .star-carousel {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 30px;
    //   font-size: 20px;
    //   margin: 0px 0 20px;
    // }
    // .star-container {
    //   .star-text {
    //     font-size: 12px;
    //   }
    // }
    .attraction-modal-container {
      margin-bottom: 15px;
    }

    .product-container {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;

      h2{
        font-weight: 500 ;
      }
      .product-rating-review {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
      }

      .product-image {
        width: 110px;
        height: 100px;

        @media screen and (max-width: 576px) {
          width: 100px;
          height: 80px;
        }

        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }

      h2 {
        font-size: 17px;
        margin-bottom: 5px;
      }
    }
    .product-detail {
      display: flex;
      // flex-wrap: wrap;
      gap: 20px;
      align-items: center;
      justify-content: space-between;

      p{
        color: #413c3c;
      }

      a{
        outline: none;
      }

      .directions-btn {
        background: #5256c8;
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 30px;
        border-radius: 5px;
        font-weight: bold;
        font-size-adjust: 14px;
        padding: 10px;
        border: none;
      }
    }
    .product-desc {
      margin-top: 10px;
      font-size: 14px;

      .see-more-btn {
        border: none;
        color: blue;
        background-color: transparent;
        font-size: 12px;
      }
      h4{
        margin-bottom: 3px;
      }

      h2 {
        font-size: 17px;
      }
    }
    .review-pub-btn {
      background-color: #ffda1a;
      cursor: pointer;
      padding: 15px 25px;
      // height: 30px;
      border-radius: 15px;
      font-size: 14px;
      font-weight: 600;
      color: #2e2e2e;
      gap: 10px;
      justify-content: center;
      display: flex;
      align-items: center;
      border: 0;
      margin: auto;

      &:hover {
        opacity: 0.8;
      }
    }
    .add-icon {
      font-size: 15px;
    }

    .image-container {
      margin-bottom: 20px;
      .image-title {
        margin-bottom: 10px;
        font-size: 17px;
      }
      .carousel {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-size: 30px;

        .carousel-images {
          display: flex;
          gap: 15px;
        }

        img {
          width: 130px;
          height: 120px;
          border-radius: 4px;

          @media screen and (max-width: 576px) {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    .review-container {
      margin-bottom: 20px;
      .review-title {
        font-size: 17px;
        margin-bottom: 10px;
      }
      
      .review-content-setup-open {
        max-height: 200px;
        overflow-y: auto !important;
      }
      .review-content {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        .rating-section {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
      .see-al-btn {
        padding: 9px 15px;
        border-radius: 14px;
        background-color: #5256c8;
        color: white;
        border: none;
        margin: 10px auto;
        display: block;
        font-weight: 400;
      }
    }
  }
}
