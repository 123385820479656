.h2h-row {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  height: 45px;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.h2h-teamComparison {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.h2h-team {
  display: flex;
  flex-direction: row;
  justify-content: "center";
  align-items: "center";
  width: 40%;
}
.h2h-homeTeam {
  display: flex;
  justify-content: flex-end;
}
.h2h-awayTeam {
  display: flex;
  justify-content: flex-start;
}
.h2h-teamLogo {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.h2h-teamName {
  font-size: 11px;
  color: #2e2e2e;
  padding-top: 1px;
}

.h2h-vsContainer {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.h2h-score {
  display: flex;
  border: 1px solid #2e2e2e;
  border-radius: 100px;
  height: 22px;

  width: 100%;
  justify-content: center;
  align-items: center;
}
.h2h-date {
  font-size: 9px;
  color: #2e2e2e;
}
