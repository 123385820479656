.apply-btn-container {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 10px;

	cursor: pointer;
	.custom-apply-btn {
		box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
		background: #5256c8;
		color: white;
		font-weight: 700;
		width: 100px;
		height: 35px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		border: none;
	}
	.custom-apply-btn-disable {
		background: #5b5b5b;
		box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
		color: white;
		font-weight: 700;
		width: 100px;
		height: 35px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		cursor: not-allowed;
		opacity: 0.6;
	}
}
