.match-not-found {
  margin: 5px;

  .text {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;

    @media (max-width: 1000px) {
      margin: 10px;
    }
  }

  .button {
    display: flex;
    justify-content: space-around;

    .button-styles {
      width: auto;
    }

    @media (max-width: 1000px) {
      margin: 10px;
    }
  }

  .image > svg:last-child {
    @media (max-width: 1000px) {
      height: 175px;
    }
  }
}
