span.filterModal-checkbox-span {
  width: 45px;
  height: 30px;
  background: white;
  cursor: pointer;
  display: inline-flex;
  top: -2px;
  position: absolute;
  border-radius: 20px;
  pointer-events: none;
  z-index: 99;
  left: -15px;
  border: 2px solid #ccc;
  transition: all 0.3s ease 0s;
}

.filterModal-checkbox:checked + .filterModal-checkbox-span:before {
  left: 19px;
  background-color: #5256c8;
}

span.filterModal-checkbox-span:before {content: "";position: absolute;height: 20px;width: 20px;background: #ccc;border-radius: 100%;top: 50%;transform: translateY(-50%);left: 2px;transition: all 0.3s ease 0s;}

.filterModal-checkbox:checked + .filterModal-checkbox-span {
  border-color: #5256c8;
}


.week-day::-webkit-scrollbar {
  width: 10px !important;
  height:10px !important;
}

.week-day > label {
  width: 14.28%;
  text-align: center;
  flex: 0 0 14.28%;
}
.week-day > label.active span {
  color: #898cd6;
}
.week-day > label span {
  width: 100%;
  display: inline-block;
  color: #fff;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: red;
}

.fixtures-list {
  display: flex;
  // overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
  scroll-padding-bottom: 0px;

  &.shrink-fixtures-list {
    height: calc(100vh - 183px) !important;
    padding-bottom: 50px !important;
  }

  .ending-text{
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
}

.leagueTitle {
  text-align: center;
  padding: 0 0 10px;
  font-weight: bold;
}
.leagueTitle {
  padding: 10px 0;
  text-align: left !important;
  position: relative;
}

.leagueTitle div {
  position: absolute;
  right: 20px !important;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}

.leagueTitle div input {
  left: -7px;
  top: 3px;
  width: 32px;
  height: 17px;
}

.fixture-list-title {
  font-size: 25px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: center;
  margin: 5px 0;
}

.fixture-list-date {
  font-size: 15px;
  font-weight: 100 !important;
}

.stadium-info {
  font-weight: 700;
  color: #2e2e2e;
  font-size: 15px;
  margin-left: 2.5%;
  margin-top: 15px;
  text-align: center;
}

.review-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;  
}

.loaders-class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.inner-loader{
  margin: 5px;
}

.radius-switch{
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-left: 6px;
  align-items: center; 
}
