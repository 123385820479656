.search-leagues-input-container {
  position: relative;
  .search-leagues-input {
    border: 1px solid #5b5b5b;
    width: 100%;
    // box-shadow: 0px 3px 6px 1px rgba(173, 173, 173, 0.6);
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    outline: none;
    text-overflow: ellipses;
    padding: 0 35px 0 10px;
    height: 35px;
    color: #7D8185;
    font-weight: 500;

    &.filterModal-search-leagues-input {
      box-shadow: none !important;
      border: 1px solid #ebebeb;
    }
  }
  .ios-search-leagues-input {
    font-size: 16px !important;
  }

  .search-icon {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}
