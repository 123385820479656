.ratings {
  display: flex;
  align-items: center;
  height: 12px;

  .rating {
    color: #5b5b5b;
    font-size: 9px;
    margin-top: 4px;
  }
  
  .stars {
    margin: 1px 4px 2px 4px;
  }
}


.addition-info {
  
  margin-left: 4px;

}

.rate-block {
  display: flex;
}
