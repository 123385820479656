.statistics {
  display: flex;
  flex-direction: column;
  color: #2e2e2e;
  padding: 20px 10px;
  height: 100%;
  overflow-y: scroll !important;

  .statistics-spinner {
    height: 91.5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statistics-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding: 8px 10px;

    .team-score {
      font-size: 16px;
      font-weight: 700;
      color: #8b8b8b;

      @media (max-width: 1000px) {
        font-size: 13px;
      }
      &.highlighted-score {
        color: #5256c8;
      }
    }

    .statistics-title {
      font-weight: 500;
      font-size: 13px;

      @media (max-width: 1000px) {
        font-size: 10px;
      }
    }
  }
}

.no-statistics-message {
  font-weight: 700;
  text-align: center;
  margin: 0 1%;
  position: relative;
  top: 1px;
  color: #2e2e2e;
}
