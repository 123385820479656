.reviews-map-container {
  width: 100%;
  height: 100%;
}

.reviews-modal-stadiumIcon {
  width: 40px;
  height: 40px;
  position: relative;
  top: -17px;
  left: -19px;
  z-index: 998;
}

.reviews-modal-supporterIcon {
  position: absolute;
  transform: translate(-20%, -85%);
}
