.error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 60px);

  .error-img {
    // width: 400px;
  }
}
