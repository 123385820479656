.h2h-title {
  color: #2e2e2e;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.h2h-container {
  margin-bottom: 20px;
}
