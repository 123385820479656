.profile-page {
  margin-top: 20px;
  padding: 20px;

  .user-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .user-data-container {
    max-width: 1300px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
    row-gap: 30px;
    margin: auto;

    @media (max-width: 768px) {
      max-width: auto;
      width: 100%;
      grid-template-columns: 1fr;
    }

    .user-data {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
      gap: 10px;
      max-width: 600px;
      width: 100%;

      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

      input {
        color: #333;
      }

      label {
        flex-grow: 1;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .country-select-dropdown {
        max-width: 550px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        @media (max-width: 768px) {
          width: 100%;
          max-width: 100%;
        }
      }

      .fav-team-selector,
      .select__control,
      .select__value-container {
        max-width: 550px;
        width: 100%;
        height: 50px;
        min-height: 50px;
        font-size: 16px;
        font-weight: 400;
        font-size: 16px;
        align-items: center;
        @media (max-width: 768px) {
          height: auto;
          width: 100%;
          max-width: 100%;
        }
      }
      .select__value-container {
        padding: 0px 8px;
      }
      .select__input-container {
        padding: 0;
        margin: 0;
      }
      .multi-select-container .select__control {
        border-color: #e2e2e2;
      }


      input:not([type="checkbox"]),
      textarea,
      .tags-container {
        max-width: 550px;
        width: 100%;
        padding: 15px;
        height: 50px;
        font-size: 16px;
        border-radius: 8px;
        border: 1px solid #e2e2e2;
        outline: none;
        @media (max-width: 768px) {
          max-width: 100%;
          width: 100%;
        }
      }
      textarea {
        min-height: 200px;
      }
      .tags-container {
        display: flex;
        align-items: center;
        border: none;
        padding: 20px 0;
        gap: 20px;
        flex-wrap: wrap;
        height: auto;

        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          width: fit-content;
          padding: 3px 10px;
          background-color: rgb(94, 94, 219);
          border-radius: 8px;
          font-size: 14px;
          color: white;
          margin: 0;
          padding: 10px;

          .tag-data {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              background: white;
              padding: 2px;
            }
          }
          .delete-icon {
            width: 15px;
            height: 15px;
            cursor: pointer;
            transition: transform 0.3s ease;
          }
        }
      }
    }
    .checbox-container{
      display: flex;
      align-items: center;
      gap: 10px;

      .checkbox-input{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    .update-btn {
      margin: 40px auto;
      width: 150px;
      padding: 15px 0;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
      background-color: #6c63ff;
      color: white;
      border-radius: 10px;
      border: none;
    }
  }
}
