.unlock-modal-cont {
  text-align: center;
  h1 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    color: rgb(104, 128, 237);

    @media (max-width: 576px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .hidden-img-cont {
    margin: 30px auto;
    @media (max-width: 576px) {
      margin: 20px auto;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      //   @media (max-width: 576px) {
      //     margin: 20px auto;
      //   }
    }
  }

  .btns-container {
    button {
      color: white;
      font-size: 15px;
      margin: auto;
      margin-bottom: 15px;
      padding: 13px 20px;
      text-align: center;
      width: 90%;
      border: none;
      border-radius: 10px;

      @media (max-width: 576px) {
        font-size: 12px;
        padding: 10px 20px;
        margin-bottom: 10px;
      }
    }
    .signin-btn {
      background-color: red;
    }
    .close-btn {
      background-color: rgb(104, 128, 237);
    }
  }
}

.unlock-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px 20px 20px;
  max-width: 500px;

  @media (max-width: 1000px) {
    padding: 8px 13px 0;
  }

  @media (max-width: 480px) {
    width: 280px !important;
  }
}
