.sub-tabs1 {
	display: flex;
	flex-direction: column;
	// height: calc(100% - 150px);
}

.main-tabs1 {
	height: 100%;
	display: flex;
	flex-direction: row;

	@media (max-width: 500px) {
		flex-direction: column;
	}
}

.tabs1 {
	display: flex;
	flex-direction: column;
	width: fit-content;
	justify-content: space-evenly;
	align-items: center;
	// height: 8.5%;
	font-weight: 500;
	color: #2e2e2e;
	font-size: 25px;

	&.home-details-tabs1 {
		height: 180px !important;
		width: 100% !important;
		max-width: 280px !important;
		flex-shrink: 0;
		box-shadow: none;
	}

	// @media (max-width: 1000px) {
	//   font-size: 14px;
	//   height: auto;
	//   margin: 0 auto;
	// }

	&.negative-margin1 {
		padding: 0 !important;
		justify-content: flex-start !important;
		border-top: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
	}

	.tab1 {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		padding: 8px;

		@media (max-width: 800px) {
			width: 100%;
			padding: 16px;
			font-size: 14px;
		}

		&.active-tab1 {
			position: relative;
			background-color: #5256c8;
			color: white;

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background-color: #5256c8;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
}

.tab-component1,
.sub-tab-component1 {
	height: 91.5%;
	padding: 16px;
	padding-top: 0;
	font-size: 20px;
	color: #2e2e2e;
	line-height: 30px;
	font-weight: normal;
	text-align: justify;

	@media (max-width: 1000px) {
		height: auto;
	}
}

.home-tab-component1 {
	background: #f8f8f8;
	padding: 16px;
	max-width: 900px;
	padding-top: 0;
	font-size: 20px;
	color: #2e2e2e;
	line-height: 30px;
	text-align: justify;

	@media (max-width: 800px) {
		padding: 12px;
	}
}

.live-details-tabs {
	height: calc(100vh - 339px) !important;

	@media (max-width: 1000px) {
		height: auto;
	}
}
