.wishlist {
  padding: 20px 80px;

  .wishlist-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .fixture-list-item {
      width: 100%;
    }

    .fixture-list-item-container {
      width: 400px;
      // height: 160px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .wishlist-list-item {
      // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      @media (max-width: 768px) {
        width: 100%;
      }

      .radius {
        font-size: 10px !important;
      }
    }
  }

  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .bold {
    font-weight: 700;
    color: rgb(85, 82, 82);
    margin-right: 5px;
  }
  .blue {
    color: blue;
    margin-right: 5px;
  }
  @media (max-width: 576px) {
    padding: 1px 10px;
    font-size: 15px;
  }
  .top-heading {
    margin-bottom: 20px;
    text-align: center;

    p {
      color: rgb(85, 82, 82);
      font-weight: 500;
    }

    h1 {
      font-size: 29px;
      @media (max-width: 576px) {
        font-size: 20px;
      }
    }
    @media (max-width: 1000px) {
      margin-top: 60px;
    }
  }

  .summary-sec {
    p:first-of-type {
      font-style: italic;
      font-weight: 500;
      color: rgb(85, 82, 82);
    }
  }
  .table-content {
    margin-bottom: 30px;
    ul {
      margin-left: 15px;
    }
    li {
      list-style-type: decimal;
      padding-left: 10px;
      color: blue;
    }
    li a {
      margin-bottom: 7px;
      color: blue;
      text-decoration: none;
      font-size: 15px;
    }
  }
  .headings {
    margin-bottom: 30px;

    ul {
      margin-left: 40px;
    }

    li {
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
}
