.live-matches-list {
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 189px);

  &.filtered-not-live {
    height: calc(100vh - 250px);
  }
}

.live-matches-list-not-today {
  height: calc(100vh - 185px) !important;
}

.live-matches-league-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  .live-matches-league-logo {
    width: 35px;
    height: 35px;
    object-fit: contain;
    margin: 0 1%;
  }

  .live-matches-league-text {
    font-weight: 300;
    text-align: center;
    margin: 0 1%;
    position: relative;
    top: 1px;
  }
}

.yellow-card-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yellow-card {
  width: 50%;
  margin: 20px 0 10px 0;
}

.yellow-card-text {
  font-weight: 700;
  color: #2e2e2e;
  font-size: 14px;
  text-align: center;
}
