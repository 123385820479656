.help-supporter-btn {
  position: absolute;
  background-color: white;
  color: #2e2e2e;
  right: 5px;
  bottom: 8px;
  width: 45%;
  height: 45px;
  font-size: 9px;
  font-weight: 700;
  padding: 4px 5px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .help-supporter-img {
    width: 35px;
    height: 35px;
  }
  span {
    padding-left: 5px;
  }

  @media (min-width: 426px) {
    display: none;
  }
}
