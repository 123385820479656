.supporter-card-container {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    display: none !important;
  }
  position: relative;
  cursor: pointer;
  .supporter-card {
    background-color: white;
    border-bottom: 1px solid #ebebeb;
    color: #2e2e2e !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: max-content;
    padding: 10px 15px;
    .card-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex: 70%;
      .supporter-name {
        font-weight: 500;
        font-size: 12px;
      }

      .supporter-place-description {
        font-size: 10px;
        font-weight: 300;
        margin: 3px 0;
      }
      .supporter-contact {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .supporter-team {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex: 39%;
          justify-content: space-between;
          width: max-content;
          .supporter-team-logo {
            width: 25px;
            height: 25px;
            object-fit: contain;
          }

          .supporter-team-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .supporter-type {
              font-size: 12px;
              font-weight: 500;
              @media (max-width: 1000px) {
                font-size: 11px;
                line-height: 12px;
              }
            }

            .supporter-team-name {
              font-size: 10px;
              font-weight: 300;
            }
            @media (max-width: 1000px) {
              margin-left: 5px;
            }
          }
        }

        .supporter-contact-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          flex: 57%;

          text-align: right;
          justify-content: center;
          margin: 0 5px;

          .supporter-number {
            font-size: 11px;
            font-weight: 300;
            width: 95%;
            text-align: left;
          }

          .supporter-address {
            font-weight: 700;
            font-size: 11px;
            line-height: 1.2;
            width: 95%;
            text-align: left;
          }
        }
      }
    }

    .card-static-container {
      flex: 20%;
      display: flex;
      height: 96px;
      align-items: center;
      .card-static-img {
        width: 100%;
        height: 90px;
        object-fit: cover;
        border-radius: 7px;
      }
    }
  }

  .card-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #2e2e2e;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none !important;

    .card-txt--strong {
      color: #379af0;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.location-icon {
  position: relative;
  top: 1px;
}
