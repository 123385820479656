.league-item {
	position: relative;
	width: 100%;
	margin: 0 2px 4px 2px;
	display: flex;
	justify-self: center;
	align-items: center;
	background-color: white;
	border-radius: 7px;
	border: 1px solid #d1cece;
	height: 85px;
	cursor: pointer;

	@media (max-width: 1000px) {
		height: 75px;
	}

	&.selected-league-item {
		border-color: #379af0;
		box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
	}

	.league-logo {
		width: 60px;
		height: 30px;
		margin: 0 auto;
		object-fit: contain;

		@media (max-width: 1000px) {
			width: 70px;
			height: 30px;
		}
	}

	.league-name {
		position: absolute;
		left: 5px;
		top: 5px;
		font-size: 9px;
		font-weight: 700;
		color: #2e2e2e;
		max-width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		@media (max-width: 1000px) {
			font-size: 7px;
			max-width: 80px;
		}
	}

	.country-name {
		position: absolute;
		left: 5px;
		bottom: 5px;
		font-size: 9px;
		text-transform: capitalize;
		font-weight: 700;
		color: #2e2e2e;
		@media (max-width: 1000px) {
			font-size: 7px;
		}
	}

	.checkmark-icon {
		background-color: #379af0;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 5px;
		top: 5px;

		@media (max-width: 768px) {
			width: 15px;
			height: 15px;

			svg {
				width: 10px;
				height: 10px;
			}
		}
	}
}
