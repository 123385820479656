.about-container {
	width: 100%;
	//   display: flex;
	//   align-items: center;
	//   justify-content: center;
	max-width: 80%;
	margin: 0 auto 50px;
	.Cause-section {
		margin-bottom: 59px;
		margin-top: 51px;
	}
	.heading {
		font-size: 30px;
		font-weight: 700;
		line-height: 45px;
		text-align: center;
		margin-bottom: 2px;
	}
	.Cause-section {
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		text-align: center;
		margin-bottom: 30px;
	}
	.gallery {
		display: flex;
		// flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;
		width: 100%;
		max-width: 1580px;
		margin: 0 auto;
		padding: 20px;
	}

	.gallery-item {
		flex: 1 1 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.gallery-image {
		width: 100%;
		max-width: 150px;
		height: 150px;
		border-radius: 20px;
		transition: transform 0.3s;
	}

	.gallery-image:hover {
		transform: scale(1.05);
	}

	.gallery-text {
		font-size: 20px;
		color: #2e2e2e;
		font-weight: 400;
		margin-top: 10px;
		text-align: center;
	}

	/* Responsive Adjustments */
	@media (max-width: 1200px) {
		.gallery {
			gap: 15px;
		}
		.gallery-image {
			max-width: 200px;
		}
		.gallery-text {
			font-size: 18px;
		}
	}

	@media (max-width: 768px) {
		.gallery {
			justify-content: center;
		}
		.gallery-item {
			flex: 1 1 45%; /* Two columns on tablets */
		}
		.gallery-image {
			max-width: 180px;
		}
		.gallery-text {
			font-size: 16px;
		}
	}

	@media (max-width: 480px) {
		.gallery {
			flex-direction: column;
			gap: 10px;
		}
		.gallery-item {
			flex: 1 1 100%;
		}
		.gallery-image {
			max-width: 100%;
			border-radius: 15px;
		}
		.gallery-text {
			font-size: 14px;
		}
	}
	.about-title {
		font-size: 40px;
		font-weight: 700;
		line-height: 60px;
		text-align: center;
	}

	.about-detail {
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		text-align: center;
		color: #2e2e2e;
	}
	.about-gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;
		width: 100%;
		max-width: 1580px;
		margin: 0 auto;
		padding: 20px;
	}

	.about-item {
		flex: 1 1 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.about-gallery-image {
		width: 100%;
		max-width: 450px;
		height: auto;
		border-radius: 20px;
		transition: transform 0.3s;
	}

	.gallery-image:hover {
		transform: scale(1.05);
	}

	.about-text {
		font-size: 20px;
		color: #2e2e2e;
		font-weight: 400;
		margin-top: 10px;
		text-align: center;
	}

	/* Responsive Adjustments */
	@media (max-width: 1200px) {
		.about-gallery {
			gap: 15px;
		}
		.gallery-image {
			max-width: 200px;
		}
		.about-text {
			font-size: 18px;
		}
	}

	@media (max-width: 768px) {
		.about-gallery {
			justify-content: center;
		}
		.about-item {
			flex: 1 1 45%;
		}
		.gallery-image {
			max-width: 180px;
		}
		.about-text {
			font-size: 16px;
		}
		.about-details {
			font-size: 16px;
		}
	}

	@media (max-width: 480px) {
		.about-gallery {
			flex-direction: column;
			gap: 10px;
		}
		.about-item {
			flex: 1 1 100%;
		}
		.gallery-image {
			max-width: 100%;
			border-radius: 15px;
		}
		.about-text {
			font-size: 14px;
		}
		.about-details {
			font-size: 14px;
		}
	}
	.tabimg {
		width: 400px;
		height: 400px;
		margin: auto;
	}
	@media (max-width: 1000px) {
		.tabimg {
			width: 200px;
			height: 200px;
			margin: auto;
		}
	}
	.tab-section {
		display: flex;
		flex-wrap: wrap;
		padding: 20px;
		.tabs {
			width: 100%;
			height: 100%;
		}
	}
	@media (min-width: 1540px) {
		.tab-section {
			flex-wrap: nowrap;
		}
	}
	@media (max-width: 1000px) {
		.tab-section {
			flex-direction: column;
			padding: 0px;
			flex-wrap: nowrap;
		}
	}
	.social-section {
		padding: 24px;
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		.social-icons {
			size: 24px;
			display: flex;
			align-items: center;
			gap: 20px;
		}
		.social-text {
			font-style: italic;
			font-weight: 700;
			font-size: 25px;
			color: #2e2e2e;
		}
	}
}
