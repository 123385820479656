.bottom-sheet {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
	z-index: 9999;
	animation: slide-up 0.5s ease-out;
	border-radius: 10px 10px 0 0;
	padding: 1rem;
}

.bottom-sheet.closing {
	animation: slide-down 0.5s ease-out forwards;
}

@keyframes slide-up {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes slide-down {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}

.sheet-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: 1rem;
}

.heading {
	font-size: 16px;
	font-weight: bold;
	color: #7444c2;

	@media (max-width: 640px) {
		font-size: 14px;
	}
}

.text {
	font-size: 14px;
	color: #333;

	@media (max-width: 640px) {
		font-size: 12px;
	}
}

.btn-container {
	display: flex;
	gap: 1rem;
	width: 100%;
	justify-content: center;
	margin-top: 0.5rem;
	margin-bottom: 3rem;
}

.reject-all-button,
.accept-all-button {
	padding: 10px 20px;
	border-radius: 5px;
	border: 1px solid #7444c2;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reject-all-button {
	background-color: #ffffff;
	color: #7444c2;
}

.accept-all-button {
	background-color: #7444c2;
	color: #ffffff;
}
