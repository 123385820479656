// .mobile-fixtures-list .mobile-fixtures-list-item-container {
//   padding: 5px 10px !important;
//   flex-direction: row;
//   align-items: center;
//   height: auto;
//   position: relative;
// }
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows
  .mfli-date-time-container
  .mfli-time {
  display: none;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows
  .mfli-date-time-container
  .mfli-separator-line {
  display: none;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .flex-row
  .mfli-time {
  display: inline-block !important;
  border-left: 1px solid #000;
  text-align: right !important;
  padding-left: 5px;
  top: 0;
  width: 35px;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows:last-child
  .mfli-date-time-container {
  justify-content: flex-end;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-leagueNameView {
  display: none !important;
}
.mobile-fixtures-list .mobile-fixtures-list-item-container .mfli-teamsRow {
  width: 70% !important;
  order: 2;
  // display: flex;
  padding-left: 10px;
  // justify-content: space-between;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows:first-child {
  width: 15%;
  height: auto;
  order: 1;
  min-width: 45px;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows
  .mfli-date-time-container
  .flex-row
  svg {
  display: none;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows:last-child
  .mfli-date-time-container
  > svg {
  display: none;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows:last-child {
  order: 3;
  width: 15%;
}

// .mobile-fixtures-design .mobile-fixtures-list-item-container .mfli-rows .mfli-date-time-container {
//   display: none;
// }
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-teamsRow-left {
  width: 100%;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows
  .mfli-radius {
  justify-content: center;
}
.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-rows:last-child
  .mfli-venueDate {
  display: none;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-vs {
  display: none;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-sub
  > div:last-child {
  display: none !important;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-main {
  justify-content: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-sub {
  width: 100%;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-main
  .mfli-imgWrapper {
  order: 1;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-main
  span.mfli-title {
  order: 2;
}
img.info-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-top: 5px;
  margin-right: 3px;
}

.mobile-fixtures-list
  .mobile-fixtures-list-item-container
  .mfli-teamsRow
  .mfli-teamLogo {
  width: 20px;
  object-fit: contain;
  height: 16px;
}
.mobile-fixtures-list-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 67px;

  background-color: #fbfbfb;
  margin-bottom: 10px;
  // border-bottom: 1px solid black;
  // padding-left: 2px;
  // padding-right: 10px;
  border-bottom: 1px solid #ebebeb;
  scroll-snap-align: start;
  justify-content: space-between;
  &.selected-mobile-fixture-list-item {
    background-color: #f8f8f8;
  }
}

.mfli-date-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 33.3%;
  // justify-content: space-evenly;
}

.mfli-separator-line {
  height: 10px;
  width: 1px;
  background-color: #2e2e2e;
  margin: 0 1px;
}

.mfli-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 20px;
}

.mfli-time {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  position: relative;
  margin: 0 2px;
}

.mfli-live-box {
  background-color: rgba(245, 164, 164, 0.8);
  width: 30px;
  height: 15px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  .mfli-live-dot {
    margin: 0 3%;
    height: 4px;
    width: 4px;
    background-color: rgb(240, 79, 79);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 0px;
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
  }
  span {
    margin: 0 3%;
    font-weight: 900;
    color: rgb(240, 79, 79);
    font-size: 9px;
  }
}

.mfli-statusShort {
  color: #2ab300;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  top: 1.5px;
  left: 4px;
}

.mfli-leagueNameView {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex: 33.3%;
}

.mfli-leagueName {
  color: #f5f5f5;
  padding: 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 900;
  text-align: center;
  padding-bottom: 1px;
  font-size: 10px;
  @media (max-width: 800px) {
    font-size: 9px;
  }
}
.mfli-radius {
  display: flex;
  flex: 33.3%;
  font-size: 10px;
  text-align: right;
  font-weight: bold;
  justify-content: flex-end;
}
.mfli-venueDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mfli-venue {
  color: #5b5b5b;
  font-size: 10px;
  font-weight: bold;
}

.mfli-live-match-star {
  background: #d8d8d8;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  top: -2px;
  &.highlighted {
    background: #ffda1a;
  }
}

.mfli-moreInfo {
  color: #5256c8;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
}
.mfli-teamsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mfli-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 46%;
}
.mfli-title {
  color: #5b5b5b;
  font-size: 13px;
  font-weight: bold;
  font-weight: 300;
  @media (max-width: 800px) {
    font-size: 13px;
    line-height: 1;
  }
}

.mfli-subTitle {
  color: #5b5b5b;
  font-size: 13px;
  font-weight: bold;
  font-weight: 300;
  @media (max-width: 800px) {
    font-size: 13px;
    line-height: 1;
  }
}

.mfli-imgWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0 5px;
}

.mfli-teamLogo {
  width: 22px;
  height: 22px;
  object-fit: cover;
}
.mfli-vs {
  background-color: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
}
.mfli-vsText {
  font-size: 7px;
  text-align: center;
}
.mfli-leagueLogo {
  width: 75%;
  height: 75%;
}
.mfli-leagueImgWrapper {
  position: relative;
  width: 22px;
  height: 22px;
  right: 3px;
  display: flex;
  justify-content: center;
}

.mfli-sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 46%;
}

.mobile-fixture-list-title {
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  scroll-snap-align: start;
  margin-bottom: 1px;
  border-bottom: 1px solid #ebebeb;

  height: 67px;
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    height: auto;
  }
}
.game-list {
  padding: 5px 0px !important;
  height: 28px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
}
.game-list .gl-title img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.game-list .gl-title {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 0 0 8px;
  align-items: center;
  display: flex;
  gap: 10px;
}

.game-list .gl-total {
  background-color: #5256c8;
  width: 45px;
  height: 28px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.game-list + .game-list {
  border-top: 1px solid #000;
}
.mobile-fixture-list-title-inner {
  margin: 11px 0;
  padding: 7px 40px;
  border: 1px dashed #2e2e2e;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #2e2e2e;
}

.mobile-fixture-list-title-inner-icon {
  width: 29px;
  height: 29px;
}
.mobile-fixture-list-title-text {
  position: relative;
  top: 2px;
  left: 8px;
}

.hide-item {
  // visibility: hidden;
  border-bottom: 0;
  span {
    height: 67px;
    span {
      font-size: 0;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.count-arrow-mobile {
  position: absolute;
  right: -9px;
  bottom: 6px;
}

.mobile-fixture-team-container {
  .mobile-fixture-team-data{
    display: flex;
    align-items: center;
    gap: 10px;

    .mobile-fixture-wishlist {
      width: 15;
      border: none;
      background-color: transparent;

      svg{
        width: 15px;
        height: 15px;
        color: rgb(244, 77, 105);
      }
    }
  .mobile-fixture-team {
    .vs-txt {
      font-weight: 700;
      font-size: 22px;
      text-align: center;

      @media (max-width: 576px) {
        font-size: 15px;
        font-weight: 500;
      }
    }
    .vs-date-txt {
      font-size: 10px;
      text-align: center;
      width: 60px;

      @media (max-width: 576px) {
        font-size: 8px;
      }
    }
  }
}
}
.date-time-container {
  display: flex;
  // padding: 10px;
  // width: 100%;
  // background-color: white;
  gap: 5px;
  align-items: center;
  font-size: 10px;
  p {
    color: #5256c8;
  }
}
.mobile-fixture-heart-section{
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
 
  .mobile-fixture-wishlist {
    width: 15;
    border: none;
    background-color: transparent;

    svg{
      width: 15px;
      height: 15px;
      color: rgb(244, 77, 105);
    }
  }
  .mobile-match-details-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #5256c8;
    font-size: 12px;
  }
}