.rc-slider-dot {
  display: none;
}

.rc-slider-rail,
.rc-slider-track {
  height: 1px !important;
  border-radius: 0 !important;
  background-color: #2e2e2e !important;
}

.rc-slider-handle {
  margin-top: -6px;
  background-color: #ffda1a !important;
  border-color: #ffda1a !important;
}

.rc-slider-mark-text {
  font-size: 9px;
  color: #2e2e2e !important;
  &::after {
    content: "";
    display: block;
    background-color: #5b5b5b;
    height: 8px;
    width: 1px;
    position: absolute;
    left: 50%;
  }
}

.rc-slider-mark {
  width: 100%;
  position: relative;
}

.rc-slider-mark {
  top: -29px !important;
}
