.live-match-list-item {
  cursor: pointer;
  color: #2e2e2e;
  height: 120px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    .upper-content {
      border-top: 1px solid #5256c8;
      border-left: 1px solid #5256c8;
      border-right: 1px solid #5256c8;
    }

    .lower-content {
      border-bottom: 1px solid #5256c8;
      border-left: 1px solid #5256c8;
      border-right: 1px solid #5256c8;
    }
    .venue-name {
      border-top-color: 1px solid #5256c8;
      border-left-color: 1px solid #5256c8;
      border-right-color: 1px solid #5256c8;
    }
  }

  .upper-content {
    flex: 55%;
    width: 100%;
  }
  .lower-content {
    flex: 45%;
    width: 100%;
  }

  .upper-content {
    background-color: #f5f5f5;
    display: flex;
    justify-self: center;
    align-items: center;
    border: 1px solid #f5f5f5;

    .live-match-score {
      font-weight: 700;
      font-size: 22px;
      flex: 15%;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 17px;
      }
    }

    .live-match-team {
      flex: 40%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.live-match-awayTeam {
        justify-content: flex-start;
      }

      .live-match-teamName {
        font-weight: 700;
        font-size: 13px;

        @media (max-width: 1024px) {
          font-size: 12px;
        }
        @media (max-width: 850px) {
          font-size: 10px;
        }
      }

      .live-match-teamLogo {
        width: 30px;
        height: 30px;

        @media (max-width: 1024px) {
          width: 25px;
          height: 25px;
        }

        &.live-match-homeTeam-logo {
          margin-left: 10px;
        }

        &.live-match-awayTeam-logo {
          margin-right: 10px;
        }
      }
    }

    &.upper-content-focus {
      border-top-color: #5256c8;
      border-left-color: #5256c8;
      border-right-color: #5256c8;
    }
  }

  .lower-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;

    &.lower-content-focus {
      border-bottom-color: #5256c8;
      border-left-color: #5256c8;
      border-right-color: #5256c8;
    }

    .lower-content-left-side,
    .lower-content-right-side {
      display: flex;
      align-items: center;
    }

    .lower-content-left-side {
      width: 60%;
      justify-content: flex-start;

      .gap {
        margin: 0 1%;
      }

      .no-gap {
        margin: 0 2%;
      }

      @media (max-width: 1000px) {
        width: 70%;
      }
    }

    .lower-content-right-side {
      width: 40%;
      @media (max-width: 1000px) {
        width: 30%;
      }
      justify-content: space-evenly;
    }

    .lower-content-right-side--right {
      justify-content: flex-end;
      margin-right: 10px;
    }

    .elapsed-time {
      background: #2ab300;
      height: 20px;
      width: max-content;
      padding: 0 5px;
      border-radius: 2px;
      font-weight: 900;
      color: white;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        position: relative;
        top: 1px;
        padding-left: 3px;
      }
    }

    .live-box {
      background-color: rgba(245, 164, 164, 0.8);
      width: 50px;
      height: 20px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .live-dot {
        margin: 0 3%;
        height: 6px;
        width: 6px;
        background-color: rgb(240, 79, 79);
        border-radius: 50%;
        display: inline-block;
        -webkit-animation: 1s blink ease infinite;
        -moz-animation: 1s blink ease infinite;
        -ms-animation: 1s blink ease infinite;
        -o-animation: 1s blink ease infinite;
        animation: 1s blink ease infinite;
      }

      span {
        margin: 0 3%;
        font-weight: 900;
        color: rgb(240, 79, 79);
        font-size: 12px;
      }
    }

    .live-time {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      span {
        position: relative;
        top: 1px;
        left: 2px;
      }
    }
    .live-ft {
      background: #2ab300;
      height: 20px;
      width: max-content;
      padding: 0 5px;
      border-radius: 2px;
      text-align: center;
      font-weight: 900;
      color: white;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .live-match-distance {
      font-size: 12px;
      font-weight: 500;
      .live-match-radius {
        color: green;
      }
    }
    .match-detail-btn {
      background: #5256c8;
      color: white;
      font-weight: 900;
      font-size: 13px;
      padding: 5px 8px;
      border-radius: 4px;
      cursor: pointer;

      @media (max-width: 1024px) {
        font-size: 10px;
      }
    }

    .live-match-star {
      background: #d8d8d8;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      @media (max-width: 1024px) {
        height: 26px;
        width: 26px;
      }
      &.highlighted {
        background: #ffda1a;
      }
    }
  }
}

.on-live-details {
  max-width: 430px;

  @media (max-width: 1000px) {
    width: 95%;
    margin: 0 auto 20px auto;
  }

  &:hover {
    .upper-content {
      border-color: transparent;
    }

    .lower-content {
      border-color: transparent;
    }
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
