.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker-wrapper {
  width: 100% !important;
  height: 35px;
  display: flex;
  align-items: center;
}

.react-datepicker__input-container {
  width: 100% !important;

  input {
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
  }
}

.datepicker-container {
  border: 1px solid #5b5b5b;
  padding: 0 12px;
  border-radius: 3px;
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  background: white;

  &.filterModal-datepicker-container {
    border: 1px solid #5b5b5b;
    margin-bottom: 20px;
  }
}

.react-datepicker__header {
  background-color: transparent;
  margin-top: 20px;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.react-datepicker__current-month {
  color: #2e2e2e;
  margin-bottom: 20px;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  outline: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border: none;
}

.react-datepicker {
  border: 1px solid #5b5b5b;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.filterModal-datepicker {
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 5px 5px;
    font-size: 14px;
    font-weight: bold;
  }
}

.form-datepicker {
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 5px 10px;
    font-size: 14px;
    font-weight: bold;
  }
}

.react-datepicker__day-name {
  font-weight: bold;
  color: rgb(113, 113, 113);
  font-size: 13px;
  margin-top: 20px;
}

.calendar-dropdown {
  border-radius: 10px;
}

.react-datepicker-popper {
  width: 100%;
  transform: none !important;
  top: 28px !important;
}

.react-datepicker__month {
  margin-top: -5px !important;
}

.react-datepicker__navigation {
  margin-top: 20px;
}

.react-datepicker__day--disabled {
  color: #ccc;
}

.react-datepicker__day--in-range {
  background-color: #5256c8;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #5256c8;
  color: white;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(82, 86, 200, 0.5);
}

.custom-input {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: none;
  text-align: left;
  color: #7d8185;
  font-size: 16px;
}
