.liveIcon {
  width: 85px;
  height: 85px;
  cursor: pointer;
  position: absolute;
  transform: translate(-15%, -80%);
  z-index: 999;
}

.live-flag-images-container {
  position: absolute;
  top: -53px;
  left: 4px;
  width: 63px;
  height: 31px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .live-flag-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.live-marker {
  position: relative;
  z-index: 999;
  cursor: pointer;

  top: -5px;
}

