.leagues-list-container {
  // min-height: 200px !important;
  height: auto;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  // -webkit-overflow-scrolling: touch;
  // touch-action: pan-x;

  &.filterModal-leagues-list-container {
    // height: 265px;
    // margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    // -webkit-overflow-scrolling: touch;
    // touch-action: pan-x;

    @media (max-width: 576px) {
      // max-height: 151px;
      max-height: calc(100vh - 150px);
    }
  }

  .leagues-list {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: flex-start;
    padding-left: 4px;
    // max-height: 240px;
    max-height: calc(100vh - 530px); /* More dynamic */
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    // -webkit-overflow-scrolling: touch;
    // touch-action: pan-x;

    @media (max-width: 576px) {
      // max-height: 260px;
      max-height: calc(100vh - 560px);
    }
  }
}
