.search-bar {
  position: absolute;
  top: 10px;
  right: 0;
  color: #2e2e2e;
  left: 50px;
  @media (min-width: 1000px) {
    display: none;
  }
  .search-bar-inner {
    background-color: white;
    border-radius: 3px;
    width: 90%;
    height: 40px;
    margin: 0 auto;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    .search-bar-text {
      flex: 90% 1;
    height: 100%;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
      .search-bar-left-icon {
        margin-right: 10px;
      }
    }
    .search-bar-icon {
      flex: 10% 1;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .search-bar-right-icon {
        display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 7px;
    border-radius: 3px;
    background-color: #5256c8;
      }
    }
  }
}
