// .show-both-btns {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
//   align-items: center;
//   position: absolute;
//   right: 10px;
//   bottom: 25px;
// @media (max-width: 1000px) {
//   display: none;
// }
.show-map-location {
  background-color: white;
  cursor: pointer;
  font-weight: 700;
  padding: 5px 8px;
  height: 35px;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  font-size: 10px;
  color: #2e2e2e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  gap: 6px;
  font-family: poppins, sans-serif;
  position: absolute;
  left: 10px;
  bottom: 35px;
}

.show-all-matches {
  background-color: white;
  cursor: pointer;
  font-weight: 700;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  right: 10px;
  bottom: 25px;
  width: 130px;
  height: 35px;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  font-size: 10px;
  color: #2e2e2e;
  @media (max-width: 1000px) {
    display: none;
  }
  .show-all-matches-text {
    margin-top: 3px;
  }
}

.list-show-all-matches {
  background-color: #ffda1a;
  cursor: pointer;
  font-weight: 700;
  align-self: center;
  margin: 20px 0 0 !important;
  box-shadow: 1px 2px 4px 0px rgba(173, 173, 173, 0.6);
  border-radius: 5px;
  width: 92% !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  transform: translateX(-50%);
  bottom: 0px;
  left: 48% ;

  .list-show-all-matches-text {
    margin: 0 1%;
  }
}
// }
