.intro-message {
  position: absolute;
  right: 10px;
  top: 35px;
  z-index: 100;
  // width: 265px;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 5px;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  background-color: white;
  display: flex;
  align-items: center;
  gap: 20px;
  // justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }

  // .bear-img-backdrop {
  //   width: 30%;
  // }
  .mess {
    width: 68%;
    text-align: left !important;
    .intro-message-title {
      font-weight: 700;
      color: #2e2e2e;
      font-size: 12px;
    }

    .intro-message-description {
      color: #5b5b5b;
      font-size: 12px;
    }
  }
}
