.content {
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - 113px);
  width: 100%;
  @media (max-width: 1000px) {
    height: 100%;
    background-color: white;
    padding: 30px 0 0 0;
  }

  .icon-container-close {
    position: absolute;
    right: -32px;
    top: 10px;
    clear: both;
    z-index: 999;
    overflow-x: visible !important;
    overflow-y: visible !important;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #379af0;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
    @media (max-width: 1000px) {
      display: none !important;
    }
  }

  .icon-container-close a {
    margin-top: 5px;
  }
  .icon-container a {
    margin-top: 5px;
  }

  .icon-container {
    position: absolute;
    right: -40px;
    top: 10px;
    clear: both;
    z-index: 999;
    overflow-x: visible !important;
    overflow-y: visible !important;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #379af0;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
    @media (max-width: 1000px) {
      display: none !important;
    }
    &.red-bg {
      background-color: #f03737;
    }

    &.black-bg {
      background-color: #2e2e2e !important;
    }
  }

  .form {
    // padding: 0 30px;
    height: calc(100vh - 120px);
    @media (max-width: 1000px) {
      width: 100% !important;
      height: calc(100vh - 120px);
      padding: 0 20px 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 0px) {
      width: 100% !important;
      height: calc(100vh - 120px);
      padding: 0 8px 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .fixtures-list-container {
    @media (max-width: 1000px) {
      display: none !important;
    }
  }

  .supporter-info {
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    @media (max-width: 1000px) {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0px !important;
    height: 94%;
    }
  }
}

.filter-icon-bg {
  background-color: #ffda1a !important;
}

.filter-sort-icon-container {
  position: absolute;
  right: -80px;
  z-index: 999;
  font-size: 14px;

  @media (max-width: 1000px) {
    display: none !important;
  }
  .sort-icon-container {
    background-color: white;
    width: 140px;
    height: 40px;
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  }

  .filter-icon-container {
    background-color: #ffda1a;

    cursor: pointer;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.6);
  }
}

.home-icon {
  margin: 0 5px;
}
