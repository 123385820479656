.live-matches-dates {
  background-color: #dbdbdb;
  padding: 15px 28px;

  @media (max-width: 1000px) {
    padding: 5px 25px;
  }

  .dates-list {
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .live-matches-date {
    font-weight: 500;
    color: #2e2e2e;
    text-align: center;
    outline: none;
    cursor: pointer;

    font-size: 14px;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 1000px) {
      font-size: 10px;
    }

    @media (max-width: 800px) {
      font-size: 9px;
    }

    &.highlight-date {
      color: #5256c8;
    }
  }
}

.details-visible {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
