.tab-button {
  padding: 13px 5px;
  font-size: 13px;
  background-color: #f5f5f5;
  width: 48%;
  text-align: center;
  border-radius: 100px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5b5b5b;
  cursor: pointer;
  &.selected {
    background-color: #5256c8;
    border-color: transparent;
    color: white;
    box-shadow: 0px 10px 15px -14px rgba(0, 0, 0, 0.53);
  }
}
