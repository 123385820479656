.multi-select-container {
  .select__control {
    border: 1px solid #5b5b5b;
    border-radius: 3px;
    box-shadow: none;
    min-height: 35px;
    height: auto !important;
    font-size: 13px;
    font-weight: 400;
    &:hover {
      border-color: #5b5b5b;
    }
  }
  // Styles for iOS-specific select components
  .ios-select__control {
    font-size: 16px !important;
  }

  .select__value-container {
    height: auto !important;
    padding: 8px !important;
  }

  .select__indicator,
  .select__dropdown-indicator,
  .select__indicator-separator {
    color: #3d3a3a;
  }

  .select__menu {
    border-radius: 8px;
    font-size: 13px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .select__option {
    &:hover {
      background-color: #f0f4f8;
    }
  }

  .select__multi-value {
    background-color: #676be0;
    border-radius: 16px;
  }

  .select__multi-value__label {
    color: white;
  }

  .select__multi-value__remove {
    color: rgb(54, 54, 54);
  }
}
